import clsx from 'clsx';
import React from 'react';
import { IContextMenuItem } from './ContextMenu';
import { IconQuestion } from "../../../../icons";
import InformationTooltip from "../InformationTooltip/index";

const iconClass = 'context-menu-item__icon mr-2 fill-current text-gray-800';

interface MenuItemInfoProps {
	description?: string,
}

function MenuItemInfo({ description }: MenuItemInfoProps) {
	if (!description) {
		return null;
	}

	return (
		<InformationTooltip description={description}>
			<IconQuestion className="opacity-60" width={16} height={16} />
		</InformationTooltip>
	);
}

interface MenuItemProps {
	item: IContextMenuItem,
	onClick: React.MouseEventHandler<HTMLDivElement>
}

export function MenuItem({ item, onClick }: MenuItemProps) {

	const icon = item.icon ? React.cloneElement(item.icon, {
		className: `${item.icon.props?.className || ''} ${iconClass}`
	}) : null;

	return (
		<div className={clsx("context-menu-item hover:bg-gray-200 rounded-md px-2 py-1 w-full")}>
			<div className='context-menu-item__content flex items-center'>
				<div onClick={onClick} className="context-menu-item__name cursor-pointer flex items-center w-full">
					{icon} <div className='context-menu-item__name-text'>{item.name}</div> 
				</div>
				<MenuItemInfo description={item.description} />
			</div>
		</div>
	);
}