import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import _, { setWith } from "lodash";
import useIsMobile from "../hooks/useIsMobile";
import { addParamToUrl } from "../../../../../helpers";

import Radio from "../../../../Radio";
import Button from "../../../../Button";

import { motion } from "framer-motion";

import useEmblaCarousel from 'embla-carousel-react'
import { IconLeft, IconRight } from "../../../../../icons";
import { isMobile } from "../../../../Report/utils";

import ImageWithZoom from "../../../../ImageWithZoom";


function PreferenceOption(props: any) {
	const { id, replyValue, density, onReady, isImageLoaded } = props;
	const withCaption = replyValue && replyValue.length > 0;
	const preferenceOptionContainerRef = useRef<HTMLDivElement | null>(null);

	function onImageLoad(imageElement: HTMLImageElement) {
		  const naturalWidth = imageElement.naturalWidth;
		  const preferenceOptionContainer = preferenceOptionContainerRef?.current;
		  if (!preferenceOptionContainer) return;
		  if (density === "2x") {
		  preferenceOptionContainer.style.maxWidth = `${naturalWidth / 2}px`;
		} else {
			preferenceOptionContainer.style.maxWidth = `${naturalWidth}px`;
		}
		onReady(id);
	  }
  
	return (
	  <div
	  	ref={preferenceOptionContainerRef}
		id={"preferenceOptionContainer_"+id}
		className={clsx(
			"flex items-center justify-center",
		!isImageLoaded && "invisible"
		)}
		key={id}
	  >
		<div
		  className={clsx(
			"p-1 rounded-md border-1 transition-all duration-75 ease-in h-auto relative cursor-pointer",
			props.isSelected && "border-blue-600 inset-primary-shadow",
			!props.isSelected && "border-gray-300"
		  )}
		  onClick={(e) => {
			e.stopPropagation();
			props.onClick?.(id, replyValue);
		  }}
		>
			<Radio name={id} isOn={props.isSelected} onChange={() => {}} className="absolute top-4 left-4" />
		  <img
			src={props.image}
			alt=""
			className={clsx(
				"h-auto object-contain"
				)}
			onLoad={(e) => onImageLoad(e.currentTarget)}
		  />
		  {withCaption && <div className={clsx("flex items-start mt-4 text-gray-700 text-sm")}>{replyValue}</div>}
		</div>
	  </div>
	);
  }

export interface PreferenceSelectProps {
	data: { id: string, replyValue: string, image: string }[];
	handler: (key: string, value: any) => void;
	isRandomized: boolean;
	responseStart: boolean;
}

function PreferenceSelect(props: PreferenceSelectProps) {
	const { data, handler: updateAnswerBlock, isRandomized } = props;
	const [options, setOptions] = useState<{ id: string, replyValue: string, image: string, density?: "1x" | "2x" }[] | null>(null);
	const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
	const [withCarousel, setWithCarousel] = useState<Boolean>(true);
	const [showImages, setShowImages] = useState<Boolean>(false);
	const [imagesLoadingState, setImagesLoadingState] = useState<{[key:string]: boolean}>({});


	const isMobile = useIsMobile();

	function onPreferenceImageReady (id: string) {
		setImagesLoadingState((current) => {
			return {
			...current,
			[id]: true
			}
		});
	}

	useEffect(() => {
    // this effect checks if all images are loaded and if so, it checks if the scroll container is overflowing
    // if it is, it sets the carousel to active (we don't need carousel if images fit into the screen)
    const allImagesLoaded = options && options.every((option) => imagesLoadingState[option.id]);
    const checkScrollContainerOverflow = () => {
      if (allImagesLoaded) {
        const container = document.getElementById("embla__container");
        const isOverflowing = !!(container && container.scrollWidth > container.clientWidth);
        setWithCarousel((current) => {
          if (current !== isOverflowing) {
            emblaApi?.reInit({ active: isOverflowing });
          }
          return isOverflowing;
        });
        setShowImages(true);
      }
    };
    checkScrollContainerOverflow();
    window.addEventListener("resize", checkScrollContainerOverflow);
    return () => {
      window.removeEventListener("resize", checkScrollContainerOverflow);
    };
  }, [imagesLoadingState, options, isMobile]);


	useEffect(() => {
		setOptions(isRandomized ? _.shuffle(data) : data);
		setSelectedOptionId(null);
	}, [data]);

	useEffect(() => {
		if (!selectedOptionId) return;
		const replyValue = options?.find((option) => option.id === selectedOptionId)?.replyValue;
		updateAnswerBlock("selectedOptions", [{ id: selectedOptionId, replyValue }]);
	}, [selectedOptionId]);

	function onOptionSelected(id: string) {
		setSelectedOptionId(id);
	}

	// here is the carousel logic
	// docs: embla-carousel.com

	const [selectedSnap, setSelectedSnap] = useState(0)
	const [snapCount, setSnapCount] = useState(0)

	const [emblaRef, emblaApi] = useEmblaCarousel({ active: !!withCarousel });

	const onPrevButtonClick = useCallback(() => {
		if (!emblaApi) return
		emblaApi.scrollPrev()
	  }, [emblaApi])
	
	  const onNextButtonClick = useCallback(() => {
		if (!emblaApi) return
		emblaApi.scrollNext()
	  }, [emblaApi])

  
	const updateScrollSnapState = useCallback((emblaApi: any) => {
	  setSnapCount(emblaApi.scrollSnapList().length)
	  setSelectedSnap(emblaApi.selectedScrollSnap())
	}, [])
  
	useEffect(() => {
	  if (!emblaApi) return
  
	  updateScrollSnapState(emblaApi)
	  emblaApi.on('select', updateScrollSnapState)
	  emblaApi.on('reInit', updateScrollSnapState)
	}, [emblaApi, updateScrollSnapState])


	return (
    <>
      {snapCount > 1 && (
        <div className="preference-select__slider-controls flex justify-between items-center">
          <div className="text-gray-700 text-sm">
            {selectedSnap + 1} / {snapCount}
          </div>
          <div className="preference-select__buttons flex items-center space-x-2">
            <div
              className="flex items-center justify-center border-gray-300 border-1 rounded-lg w-10 h-10 cursor-pointer hover:opacity-50 transition-all duration-75 ease-in"
              onClick={onPrevButtonClick}
            >
              <IconLeft />
            </div>
            <div
              className="flex items-center justify-center border-gray-300 border-1 rounded-lg w-10 h-10 cursor-pointer hover:opacity-50 transition-all duration-75 ease-in"
              onClick={onNextButtonClick}
            >
              <IconRight />
            </div>
          </div>
        </div>
      )}
      <div className="embla max-w-full" ref={emblaRef}>
        <div id="embla__container" className={clsx("embla__container flex items-start", isMobile ? "gap-2" : "gap-4")}>
          {options?.map((item) => (
            <div
              className={clsx("embla__slide")}
              style={isMobile ? { flex: "0 0 97%" } : { flex: "0 0 auto" }}
              key={item.id}
            >
              <PreferenceOption
                key={item.id}
                id={item.id}
                replyValue={item.replyValue}
                image={addParamToUrl(item.image, "pwPreferenceOptionId", item.id)}
                density={item.density}
                onClick={onOptionSelected}
                onReady={onPreferenceImageReady}
				isImageLoaded={imagesLoadingState[item.id]}
                isSelected={selectedOptionId === item.id}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default PreferenceSelect;

