import React, { useMemo } from "react";
import "../../tailwind.generated.css";

import ImageWithZoom from "./ImageWithZoom";
import { IChoiceResponse } from "../../models/Response";
import { useTranslation } from "react-i18next";

type ChoiceReportProps = {
	type: "single" | "multi";
	replies: { id: string; replyValue: string }[];
	responses: IChoiceResponse[];
	questionText: string;
	withOther: boolean;
	image?: string;
};

const ChoiceReport = ({ type, replies, responses, questionText, withOther, image }: ChoiceReportProps) => {
	const { t } = useTranslation();
	const replyMap = useMemo(() => {
		const map: { [id: string]: string } = {};
		replies.forEach((reply) => {
			map[reply.id] = reply.replyValue;
		});
		return map;
	}, [replies]);

	const findReplyNameById = (id: string) => {
		if (id === "other") {
			return t("Other");
		}
		return replyMap[id] || "";
	};

	const data = useMemo(() => {
		const localData: { [key: string]: number } = {};

		// Initialize data structure
		[...replies, ...(withOther ? [{ id: "other" }] : [])].forEach((reply) => {
			localData[reply.id] = 0;
		});

		// Populate data using a single loop
		responses.forEach((response) => {
			response.selectedOptions.forEach((option) => {
				if (localData.hasOwnProperty(option.id)) {
					localData[option.id]++;
				}
			});
		});
		return localData;
	}, [replies, responses, withOther, replyMap]);

	const responsesWithOther = useMemo(() => {
		return responses.filter((response) => {
			return response.selectedOptions.some((option) => option.id === "other");
		});
	}, [responses]);

	return (
    <div className="flex flex-col gap-4">
      <div className="choice-report__question">
	  <span className="block caption">{t("Question")}</span>
		<div className="flex justify-start items-start gap-2 mb-4">
		{image && <ImageWithZoom imageSrc={image} className="rounded-default mr-6 w-12 h-12" />}
		  <div className="text-gray-700">{questionText}</div>
		</div>
	  </div>
      {Object.keys(data).map((key) => (
        <div key={key}>
          <div className="h-1 bg-gray-300 rounded-full w-full relative">
            <div
              className="h-1 rounded-full bg-[#F58E62]"
              style={{
                width: `${(data[key] / responses.length) * 100}%`,
              }}
            ></div>
          </div>
          <div className="flex justify-between w-full items-center mt-2">
            <div className="">{findReplyNameById(key)}</div>
            <div className="font-medium">
              {Math.round((data[key] / responses.length) * 100) + "%"}
              <span className="font-normal text-gray-600 text-xs">&nbsp;({data[key]})</span>
            </div>
          </div>
        </div>
      ))}
      {withOther && responsesWithOther.length > 0 && (
        <div>
          <div className="text-sm font-medium text-gray-600">{t("Other responses:")}</div>
          <div className="flex flex-col items-start flex-wrap justify-start my-2">
            {responsesWithOther.map((response, index) => (
              <div key={`response-${index}`} className="border-gray-300 rounded-2xl border-2 px-2 py-1 mr-1 my-1">
                {response.selectedOptions.find((option) => option.id === "other")?.value}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChoiceReport;
