import React, { ReactElement } from "react";
import styles from "./InformationTooltip.module.css";
import clsx from 'clsx';

interface InformationTooltipProps {
	children: ReactElement;
	icon?: ReactElement;
	title?: string;
	description: string;
	position?: "center" | "left" | "right";
}

const InformationTooltip = ({ icon, title, description, children, position = "center" }: InformationTooltipProps) => {
	return (
		<div className={styles.container}>
			{children}
			<div className={clsx(styles.tooltip, styles[position])}>
				{icon && (
					<div className={styles.icon}>
						{icon}
					</div>
				)}
				<div>
					{title && (
						<div className={styles.title}>{title}</div>
					)}
					<div className={styles.description}>{description}</div>
				</div>
			</div>
		</div>
	);
}

export default InformationTooltip;