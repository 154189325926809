import React, { useState, useEffect } from "react";
import "../../../../../tailwind.generated.css";

import Button from "../../../../Button";
import Modal from "../../../../Modal";
import Input from "../../../../Input";
import Tooltip from "../../../../Tooltip";
import cardSortIllustration from "../../../../../illustrations/cardsort_instruction.gif";
import greenCheckIllustration from "../../../../../illustrations/green_check.svg";
import dropToCreateCategory from "../../../../../illustrations/cardsort_drop_to_create_category.svg";
import { IconError, IconInfo, IconTrash } from "../../../../../icons";

import TestBranding from "./TestBranding";

import _ from "lodash";

import { Draggable, Droppable, DragDropContext, DropResult } from "@hello-pangea/dnd";
import { SortingCard, SortingCategory } from "../../../../../models/Response";
import clsx from "clsx";

import parse from "html-react-parser";
import { CardSortBlock } from "../../../../../models/Test";

import { generateId } from "../../../../../utils";

import { locale, interfaceText } from "../../../../../helpers";

const getDraggableStyle = (style: any, snapshot: any) => {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.001s`,
  };
};

const openSortEmptyState = <div className="flex flex-col items-center w-full h-full justify-center">
  <img src={dropToCreateCategory} className="mx-auto w-24 h-24" />
  <div className="text-gray-700 text-base text-center">{(interfaceText as any).test[locale()].dragCardsToCreateCategories}</div>
</div>;

type CardProps = {
  card: SortingCard,
  withImages: boolean;
  withDescription: boolean;
  i: number;
};

/* Card */

const Card = ({ card, withImages, withDescription, i }: CardProps) => {
  return (
    <Draggable key={card.id} draggableId={card.id} index={i}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getDraggableStyle(provided.draggableProps.style, snapshot)}
          className="p-2 rounded-md bg-white leading-snug border-gray-300 border-1"
        >
          {withImages && card.image && card.image !== "" && (
            <img
              src={card.image}
              className="object-cover pb-1 mx-auto"
              style={{ maxHeight: "240px" }}
            />
          )}
          <span className="block text-base">{card.value}</span>
          {withDescription && (
            <span className="block text-sm text-gray-700 mt-1">{card.description}</span>
          )}
        </div>
      )}
    </Draggable>
  )
}

type CategoryProps = {
  id: string;
  value: string | undefined;
  withImages: boolean;
  withDescription: boolean;
  cards: SortingCard[];
  isCustom?: boolean;
  updateCategoryName: (value: string, id: string) => void;
  deleteCategory: (id: string) => void;
}

/* Category */

const Category = ({ id, value, withImages, withDescription, cards, isCustom, updateCategoryName, deleteCategory }: CategoryProps) => {

  const isValidName = value && value.length > 0;

  if (id === "newCategoryPlaceholder") {
    return (
      <Droppable droppableId={id} key={id}>
        {(provided, snapshot) => (
          <div
            className={clsx(
              "p-2 rounded-md border-1 leading-snug flex-grow-0 border-dashed flex items-center justify-center",
              snapshot.isDraggingOver ? "bg-blue-200 border-blue-600" : "bg-blue-100 border-blue-400",
              withImages ? "h-64" : "h-32",
            )}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <span className="text-blue-600 text-base text-center">{(interfaceText as any).test[locale()].dragCardHereToCreateCategory}</span>
          </div>
        )}
      </Droppable>
    )
  }

  return (
    <div className="p-2 rounded-lg bg-white leading-snug flex-grow-0 shadow-xs group relative">
      <div
        data-test-id="category-name"
        className="p-2 flex items-center justify-start font-medium lg:text-lg text-base"
      >
        {isCustom ? (
          <Input
            className="max-w-full truncate"
            initialValue={value}
            placeholder={(interfaceText as any).test[locale()].enterCategoryName}
            id={`${id}-name`}
            onChange={(value) => {
              updateCategoryName(value, id);
            }}
          />
        ) : (
          value
        )}
        {isCustom && !isValidName && <Tooltip position="bottom" content={(interfaceText as any).test[locale()].categoryNameRequired}><IconError className="w-4 h-4 fill-current text-red-600" /></Tooltip>}
        {isCustom && (
          <IconTrash
            onClick={(e: any) => {
              deleteCategory(id);
            }}
            className="fill-current text-gray-700 hover:text-red-600 cursor-pointer transition-all duration-75 lg:invisible visible group-hover:visible w-5 h-5 ml-2"
          />
        )}
      </div>
      <Droppable droppableId={id} key={id}>
        {(provided, snapshot) => (
          <div
            className={clsx(
              "p-2 rounded-md leading-snug flex-grow-0 flex flex-col gap-2",
              snapshot.isDraggingOver && "border-blue-600 border-1 border-dashed bg-blue-200",
              cards.length === 0 &&
              !snapshot.isDraggingOver &&
              "h-24 border-dashed border-1 border-gray-300 flex items-center justify-center",
              cards.length === 0 && snapshot.isDraggingOver && "min-h-24 flex items-center justify-center",
              cards.length > 0 && "h-auto"
            )}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {cards.length === 0 && (
              <div className={clsx("text-base", snapshot.isDraggingOver ? "text-blue-600" : "text-gray-600")}>
                {(interfaceText as any).test[locale()].dragCardHereToAddToCategory}
              </div>
            )}
            {cards.map((card: any, i: number) => {
              return <Card card={card} withImages={withImages} withDescription={withDescription} i={i} key={card.id} />;
            })}
            {cards.length > 0 && provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}


type CardSortProps = {
  blockData: CardSortBlock;
  blockAnswer: any;
  responseStart: any;
  setResponseStart: any;
  handleBlockAnswer: (key: string, value: any) => void;
  isValidated: boolean;
  nextStepOnClick: () => void;
};

const CardSort = ({
  blockData,
  responseStart,
  setResponseStart,
  handleBlockAnswer,
  isValidated,
  nextStepOnClick,
}: CardSortProps) => {

  const categories = blockData.categories;
  const cards = blockData.cards;
  const shuffleCards = blockData.shuffleCards;
  const shuffleCategories = blockData.shuffleCategories;
  const withImages = blockData.withImages;
  const withDescription = blockData.withDescription;
  const text = blockData.text;
  const questionHtml = blockData.questionHtml;
  const isOpenSort = blockData.isOpenSort;


  const [sorting, setSorting] = useState<SortingCategory[]>([
    ..._.chain(shuffleCategories ? _.shuffle(categories) : categories)
      .map(category => ({
        id: category.id,
        value: category.value,
        isCustom: false,
        cards: [],
      }))
      .value(),
    {
      id: 'unsorted',
      value: undefined,
      isCustom: false,
      cards: shuffleCards ? _.shuffle(cards) : cards,
    },
  ]);

  useEffect(() => {
    handleBlockAnswer("sorting", sorting);
  }, [sorting]);


  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(true);

  const onCardDragEnd = (result: DropResult) => {

    const removePlaceholder = (sorting: SortingCategory[]) => {
      const placeholderCategoryIndex = sorting.findIndex(category => category.id === 'newCategoryPlaceholder');
      if (placeholderCategoryIndex !== -1) {
        sorting.splice(placeholderCategoryIndex, 1);
      }
    };

    const sourceCategory = sorting.find(category => result.source.droppableId === category.id);
    if (!sourceCategory) {
      return;
    }
    const draggedCard = sourceCategory.cards.find((card: any) => card.id === result.draggableId);
    const destination = result.destination;

    if (!destination || !destination.droppableId || !draggedCard) {
      /* If there is no destination, we need just to remove the placeholder, if it exists */

      setSorting((currentSorting) => {
        const newSorting = [...currentSorting];
        removePlaceholder(newSorting);
        return newSorting;
      });

      return;
    }

    const source = result.source;
    const oldCategoryId = source.droppableId;
    const newCategoryId = destination.droppableId;

    setSorting((currentSorting) => {
      const newSorting = _.cloneDeep(currentSorting);
      const oldCategoryIndex = newSorting.findIndex(category => category.id === oldCategoryId);
      const newCategoryIndex = newSorting.findIndex(category => category.id === newCategoryId);
      const cardIndex = newSorting[oldCategoryIndex].cards.findIndex((card: any) => card.id === draggedCard.id);
      newSorting[oldCategoryIndex].cards.splice(cardIndex, 1);
      newSorting[newCategoryIndex].cards.splice(destination.index, 0, draggedCard);
      /* If card moved to a new category, we need to update it with actual id and set isCustom to true */
      if (newCategoryId === 'newCategoryPlaceholder') {
        const newId = generateId();
        newSorting[newCategoryIndex].id = newId;
        newSorting[newCategoryIndex].isCustom = true;
      }
      if (newCategoryId !== 'newCategoryPlaceholder') {
        /* If card is dragged to an existing category, remove placeholder */
        removePlaceholder(newSorting);
      }

      return newSorting;
    });
  };

  const onBeforeCapture = () => {
    /* If isOpenSort — create a new category placeholder */
    if (isOpenSort) {
      setSorting((currentSorting) => [...currentSorting, { id: 'newCategoryPlaceholder', value: undefined, isCustom: false, cards: [] }]);
    }
  }

  function updateCategoryName(value: string, id: string) {
    setSorting((currentSorting) => {
      const newSorting = [...currentSorting];
      const categoryIndex = newSorting.findIndex(category => category.id === id);
      newSorting[categoryIndex].value = value;
      return newSorting;
    });
    return;
  }

  function deleteCategory(id: string) {
    setSorting((currentSorting) => {
      const newSorting = [...currentSorting];
      const categoryIndex = newSorting.findIndex(category => category.id === id);
      const deletedCategoryCards = newSorting[categoryIndex].cards;
      const unsortedCategoryIndex = newSorting.findIndex(category => category.id === "unsorted");
      newSorting[unsortedCategoryIndex] = {
        ...newSorting[unsortedCategoryIndex],
        cards: [...newSorting[unsortedCategoryIndex].cards, ...deletedCategoryCards]
      };
      return newSorting.filter(category => category.id !== id);
    });
    return;
  }

  const unsortedCategory = sorting.find(category => category.id === "unsorted");
  const categoriesWithoutUnsorted = sorting.filter(category => category.id !== "unsorted");

  if (!sorting) {
    return null;
  }

  if (!unsortedCategory) {
    return null;
  }

  return (
    <>
      <div
        className="cardsort flex flex-col overscroll-none break-words text-lg max-h-full overflow-auto bg-gray-100 h-full"
        style={{ overscrollBehavior: "contain" }}
      >
        <DragDropContext onDragEnd={onCardDragEnd} onBeforeCapture={onBeforeCapture}>
          <div className="cardsort__grid grid xl:grid-cols-5 lg:grid-cols-4 grid-cols-2 lg:gap-4 gap-2 h-full">
            <div className="cardsort__cards-column col-span-1 overflow-y-auto overscroll-y-none shadow-xs bg-white flex flex-col justify-between">

              <div className="cardsort__cards grow-default overflow-y-auto overscroll-y-none lg:p-6 p-2 flex flex-col gap-4">
<TestBranding />
                <div className="cardsort__task mb-4 grow-0">
                  <span className="cardsort__task-title my-4 font-medium text-lg">{parse(questionHtml || text)}</span>
                </div>

                <div className="cardsort__progress mb-4 text-base flex flex-col items-start justify-between gap-4 grow-0">
                  <div className="font-medium">{(interfaceText as any).test[locale()].cards}</div>
                  {unsortedCategory.cards.length > 0 && (
                    <div className="cardsort-progress flex items-center flex-1 w-full ">
                      <div className="cardsort-progress__count mr-2 text-gray-700 text-xs">
                        {unsortedCategory.cards.length} / {cards.length}
                      </div>
                      <div className="cardsort-progress__bar h-2 bg-gray-200 rounded-full flex-1">
                        <div
                          className="h-2 bg-blue-600 rounded-full transition-all ease-in-out duration-200"
                          style={{ width: `${((cards.length - unsortedCategory.cards.length) / cards.length) * 100}%` }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>

                <div className='h-full grow-default'>
                  <Droppable droppableId="unsorted" key="unsorted">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps} className="cardsort__cards-droppable flex flex-col gap-2 h-full">
                        {unsortedCategory.cards.length > 0 ? (
                          unsortedCategory.cards.map((card: any, i: number) => {
                            return (
                              <Card
                                card={card}
                                withImages={withImages}
                                withDescription={withDescription}
                                i={i}
                                key={card.id}
                              />
                            );
                          })
                        ) : (
                          <div className="flex flex-col gap-2">
                            <span className="text-base text-gray-700">{(interfaceText as any).test[locale()].allSorted}</span>
                          </div>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
              {responseStart && (
                <div
                  className="h-20 bg-white p-6 border-t-1 border-gray-300 justify-end flex"
                  style={{
                    zIndex: "99",
                  }}
                >
                  <Button
                    type="primary"
                    name={(interfaceText as any).test[locale()].allDone}
                    handler={() => {
                      nextStepOnClick();
                    }}
                    disabled={!isValidated}
                    className="whitespace-nowrap"
                    fullWidth
                    large
                  />
                </div>
              )}
            </div>
            <div className="cardsort__categories-column xl:col-span-4 lg:col-span-3 col-span-1 bg-gray-100 overflow-y-auto overscroll-y-none">
              {isOpenSort && categoriesWithoutUnsorted.length === 0 && openSortEmptyState}
              {categoriesWithoutUnsorted.length > 0 && (
                <div className="grid items-start auto-rows-auto xl:grid-cols-4 lg:grid-cols-2 grid-cols-1 gap-4 lg:p-6 p-2">
                  {sorting
                    .filter((category) => category.id !== "unsorted")
                    .map((category) => {
                      return (
                        <Category
                          key={category.id}
                          id={category.id}
                          value={category.value}
                          withImages={withImages}
                          withDescription={withDescription}
                          cards={category.cards}
                          isCustom={category.isCustom}
                          updateCategoryName={updateCategoryName}
                          deleteCategory={deleteCategory}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </DragDropContext>
      </div>
      <Modal
        isOpen={isInstructionModalOpen}
        setIsOpen={setIsInstructionModalOpen}
        width="440px"
        background
        disallowClickOutside
        sidebarMode
      >
        <div className="p-4 flex flex-col h-full gap-4">
        <div className="flex flex-col gap-4 flex-1">
          <TestBranding />
            <div className="flex text-2xl font-bold">{(interfaceText as any).test[locale()].cardSort}</div>
            <img src={cardSortIllustration} className="rounded-lg" />
            <div className="text-lg font-medium">
              <>
                {isOpenSort
                  ? (interfaceText as any).test[locale()].openSortInstuction
                  : (interfaceText as any).test[locale()].closedSortInstuction}
                <br />
                <br />
                {(interfaceText as any).test[locale()].doWhatComesNaturally}
              </>
            </div>
        </div>
          <div className="flex justify-end">
            <Button
              type="primary"
              name={(interfaceText as any).test[locale()].start}
              handler={() => {
                setIsInstructionModalOpen(false);
                setResponseStart(_.now);
              }}
              large
              className=""
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CardSort;
