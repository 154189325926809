import React, { PropsWithChildren } from 'react';
import { ContextMenu, IContextMenuItem } from '../../../UserAccount/Components/TestList/ContextMenu';
import { IconDelete } from '../../../../icons';
import { useTranslation } from 'react-i18next';

export interface MessageTagContextMenuProps {
	onDeleteClick?: () => void;
}

export default function MessageTagContextMenu(props: PropsWithChildren<MessageTagContextMenuProps>) {
	const { t } = useTranslation();

	const items = [
		{
			code: 'delete',
			name: t('Delete'),
			icon: <IconDelete width={16} height={16} className='size-4 fill-current' />
		}
	]

	function onContextMenuItemClick(item: IContextMenuItem) {
		if (item.code === 'delete' && props.onDeleteClick) {
			props.onDeleteClick();
		}
	}

	return <ContextMenu
		menuItems={items}
		onClick={onContextMenuItemClick}
	>{props.children}</ContextMenu>;
}