import clsx from "clsx";
import React from "react";
import TooltipWithIcon from "../../TooltipWithIcon";

export interface IScreenStatsProps {
	stats: (string | number | React.ReactElement)[][];
	delimiter?: "light" | "dark";
	className?: string;
}

const delimiterStyles = {
	light: "bg-gray-300 opacity-25",
	dark: "bg-gray-300",
};

export function ScreenStats({ stats, delimiter = "light", className }: IScreenStatsProps) {
	return (
		<div className={clsx("figma-screen-stats flex flex-col max-w-sm", className)}>
			{stats.map(([name, value, hint], index) => (
				<div key={index}>
					{index > 0 && (
						<div
							className={`figma-screen-stats__delimiter ${delimiterStyles[delimiter]}`}
							style={{ height: "1px" }}
						/>
					)}
					<div className="figma-screen-stats__item flex justify-between py-3">
						<span className="figma-screen-stats__name flex items-center opacity-75">
							{name}
							{!!hint && <TooltipWithIcon className="ml-1 inline text-gray-600" size={20} text={hint} />}
						</span>
						<span className="figma-screen-stats__value font-medium">{value}</span>
					</div>
				</div>
			))}
		</div>
	);
}
