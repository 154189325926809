import React, { useContext, useState } from "react";
import "../../tailwind.generated.css";

import { useSummarize } from "./hooks/useSummarize";

import RenderIfVisible from "react-render-if-visible";

import ImageWithZoom from "./ImageWithZoom";
import Button from "../Button";
import Tooltip from "../Tooltip";
import Loader from "../Loader";

import { IconAI } from "../../icons";
import { IOpenQuestionResponse } from "../../models/Response";
import { Trans, useTranslation } from "react-i18next";
import { TestBlockReportContext } from './ReportContext';

type AISummaryProps = {
	summary: string | null;
	isLoading: boolean;
	error: string | null;
};

const AISummary = ({ summary, isLoading, error }: AISummaryProps) => {
	const { t } = useTranslation();
	if (!summary && !isLoading && !error) {
		return null;
	}

	if (error) {
		<div className="text-red-600">{t("Something went wrong. Please, try again.")}</div>;
	}

	return (
		<div className="mt-6 mb-4 rounded-md bg-blue-200 p-6">
			<div className="font-medium flex items-center gap-2">
				<IconAI className="fill-current text-gray-800" />
				{t("Summary", { context: "ai" })}
			</div>
			<div className="mt-6">
				{summary && summary.split("\n").map((line, index) => <p key={index}>{line}</p>)}
				{isLoading && <Loader color="blue" className="my-4" />}
			</div>
		</div>
	);
};

type OpenQuestionReportProps = {
	responses: IOpenQuestionResponse[];
	questionText: string;
	image?: string;
};

const OpenQuestionReport = ({ responses, questionText, image }: OpenQuestionReportProps) => {
	const { t } = useTranslation();
	const responsesForSummary = responses.map((response) => {
		return response.textValue;
	});

	const testBlockReportContext = useContext(TestBlockReportContext);

	const { summary, getSummary, isLoading, error } = useSummarize({
		testId: testBlockReportContext?.testId as string,
		blockId: testBlockReportContext?.block.blockId as string,
		question: questionText,
		responses: responsesForSummary,
	});

	return (
		<>
		      <div className="choice-report__question">
	  <span className="block caption">{t("Question")}</span>
		<div className="flex justify-start items-center mb-4">
		  {image && <ImageWithZoom imageSrc={image} className="rounded-default mr-8 w-20 h-20" />}
		  <div className="text-gray-700">{questionText}</div>
		</div>
	  </div>
			<Tooltip
				anchorClassName="fit-content inline-block mb-4"
				content={<div className="w-48">{t("Summarizing is only available for blocks with more than 5 responses.")}</div>}
				showOnlyIf={responses?.length <= 5}
			>
				<Button
					icon={<IconAI className="fill-current text-gray-800 mr-[6px]" />}
					type="secondary"
					name={
						<span className="flex items-center">
							{t("Summarize")}
							<span className="px-1 py-[2px] bg-blue-300 text-blue-800 text-xs leading-none font-medium ml-[6px] rounded-full">{t("Beta")}</span>
						</span>
					}
					handler={async () => {
						await getSummary();
					}}
					disabled={responses?.length <= 5}
				/>
			</Tooltip>

			<AISummary summary={summary} isLoading={isLoading} error={error} />

			<div className="relative">
				<div className="pt-4">
					{responses.map((response, index) => (
						<RenderIfVisible defaultHeight={64} stayRendered={true} key={`freeform-response-${index}`}>
							<div className="mb-4">
								<div className="">{response.textValue}</div>
								<div className="text-xs text-gray-600">{response.answerId}</div>
							</div>
						</RenderIfVisible>
					))}
				</div>
			</div>
		</>
	);
};

export default OpenQuestionReport;
