import React from "react";
import { useQueryClient } from '@tanstack/react-query';
import "../tailwind.generated.css";
import { auth } from "../firebase";

import Button from "./Button";
import { useTranslation } from "react-i18next";

const SignOut = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return (
    <Button
      handler={() => {
        auth.signOut().then(() => {
          queryClient.invalidateQueries({ queryKey: ["user"] });
          queryClient.invalidateQueries({ queryKey: ["folders"] });
        });
      }}
      name={t("Sign out")}
      type="secondary"
    />
  );
};

export default SignOut;
