import React from 'react';
import { useTestBranding } from '../contexts/TestBrandingContext';

import useIsMobile from '../hooks/useIsMobile';

import clsx from 'clsx';

const TestBranding: React.FC<{ className?: string }> = ({ className }) => {
  const { avatarUrl, name } = useTestBranding();

  const isMobile = useIsMobile();

  if (!avatarUrl || !name) return null;

  return (
    <div className={clsx("flex items-center space-x-2 w-full", className)}>
      {avatarUrl && <img src={avatarUrl} alt="Brand Avatar" className={clsx("rounded-full w-6 h-6")} />}
      <span className={clsx("font-medium text-gray-800 text-sm")}>{name}</span>
    </div>
  );
};

export default TestBranding;