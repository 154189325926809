import React, { useState, useRef } from "react";
import parse from "html-react-parser";
import _ from "lodash";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import TestBranding from "./TestBranding";

import { Block, FiveSecondsBlock } from "../../../../../models/Test";
import { IconImage } from "../../../../../icons";
import Button from "../../../../Button";

import useIsMobile from "../hooks/useIsMobile";

import { locale, interfaceText, addBlockIdToUrl } from "../../../../../helpers";

import { motion } from "framer-motion";
import Modal from "../../../../Modal";

import useDimensions from "../../../../Common/Hooks/useDimensions";

type TaskProps = {
  blockData: FiveSecondsBlock & Block;
};

function Task(props: TaskProps) {
  return (
    <div>
      <div className="text-lg font-medium flex-shrink-0 break-words flex flex-col w-full">
        <div>{parse(props.blockData.questionHtml || props.blockData.text)}</div>
      </div>
    </div>
  );
}

type FiveSecondsProps = {
  blockData: FiveSecondsBlock & Block;
  showNextBlock: () => void;
  onImageLoaded: (imagePath: string) => void;
};

function FiveSeconds(props: FiveSecondsProps) {
  const [imageReady, setImageReady] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(true);
  const [showImage, setShowImage] = useState(false);

  const taskContainerRef = useRef<HTMLDivElement | null>(null);
  const { height: taskContainerHeight } = useDimensions({ ref: taskContainerRef });

  const isMobile = useIsMobile();

  const handleShowImage = () => {
    setShowImage(true);
    setIsTaskModalOpen(false);
    setTimeout(() => props.showNextBlock(), props.blockData.time * 1000);
  };

  return (
    <>
      <div className="fiveseconds__wrapper relative flex-1 flex-col h-full w-full overflow-y-hidden">
        <div
          className={clsx(
            "flex flex-1 flex-col h-full overflow-y-auto w-full relative"
          )}
          ref={taskContainerRef}
        >
          <div className={`flex justify-center items-start h-full`}>
            <div className={clsx("relative inline-flex justify-center items-center h-full")}>
                <img
                  id="fiveSecondsImage"
                  src={addBlockIdToUrl(props.blockData.image, props.blockData.blockId)}
                  alt=""
                  className={clsx(
                    "lg:mt-0 max-w-full transition-all duration-150 ease-in",
                  )}
                  style={props.blockData.scaling === "scale-down" ? { maxHeight: taskContainerHeight } : undefined}
                  onLoad={() => {
                    setImageReady(true);
                    props.onImageLoaded("image");
                  }}
                />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isTaskModalOpen}
        setIsOpen={setIsTaskModalOpen}
        width="440px"
        disallowClickOutside
        sidebarMode
        backdrop
      >
        <div className="flex flex-col gap-4 p-6 h-full justify-between">
          <div className="flex flex-col gap-4">
            <TestBranding />
            <Task blockData={props.blockData} />
            <p>{(interfaceText as any).test[locale()].fiveSecondsInstruction}</p>
          </div>
          <div className="flex justify-end">
            <Button
              name={(interfaceText as any).firstclickTesting[locale()].showImage}
              handler={handleShowImage}
              large
              icon={<IconImage width={20} height={20} className="fill-current text-white mr-2" />}
              disabled={!imageReady}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FiveSeconds;