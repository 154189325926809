import { Logic } from '../models/Logic';
import { Block, ChoiceBlock, FigmaBlock } from "../models/Test";

const CUSTOM_PANEL_PARAM = "customPanel";
const CODE_PARAM = "code";
const RESPONDENT_ID_PARAM = "pwRespId";
const PANEL_ID_PARAM = "pwPanelId";

const RESERVERD_PARAMS = [CUSTOM_PANEL_PARAM, CODE_PARAM, RESPONDENT_ID_PARAM, PANEL_ID_PARAM];

export type Test = {
  content: Block[];
  customPanelHiringCounter?: number;
  customPanelScreeningQuestions?: ChoiceBlock[];
  customPanelRedirects?: {
    screeningFailed: string;
    testCompleted: string;
    redundantAnswer: string;
  };
};

export const hasCustomPanelParams = (test: Test) => {
  return (
    test &&
    test.customPanelScreeningQuestions &&
    test.customPanelScreeningQuestions.length > 0
  );
};

export enum TesterSource {
  Toloka = "toloka",
  Link = "link",
  CustomPanel = "customPanel",
}

export const getTesterSource = () => {
  const params: any = new URLSearchParams(window.location.search);

  const testerSource = params.get(CODE_PARAM) ? TesterSource.Toloka : TesterSource.Link;
  const isCustomPanelTest = params.get(CUSTOM_PANEL_PARAM) === "true" ? true : false;

  if (isCustomPanelTest && testerSource === TesterSource.Link) {
    return TesterSource.CustomPanel;
  }

  return testerSource;
};

export function getUrlParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const params: Record<string, string> = {};
  urlParams.forEach((value, key) => {
    if (!RESERVERD_PARAMS.includes(key)) {
      params[key] = value;
    }
  });
  return params;
}

export function getRespondentParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {
    respId: urlParams.get(RESPONDENT_ID_PARAM) || null,
    panelId: urlParams.get(PANEL_ID_PARAM) || null,
  };
  return params;
}

export const getFormattedPanelUrl = (panelUrl: string) => {
  const params: any = new URLSearchParams(window.location.search);

  const httpPattern = /^((http|https):\/\/)/;

  const formattedPanelUrl = new URL(
    httpPattern.test(panelUrl) ? panelUrl : `https://${panelUrl}`
  );

  for (const [key, value] of params.entries()) {
    formattedPanelUrl.searchParams.set(key, value);
  }

  return formattedPanelUrl.toString();
};

type GetLogic = (block: Block) => Logic | undefined;

export const getBlockLogic: GetLogic = (block: Block) => {
  if (!block.logic) {
    return undefined;
  }

  const { statements, visibility, elseJumpTo, conditions, jumpTo } = block.logic;

  if (statements) {
    return {
      elseJumpTo,
      statements,
      visibility
    };
  }

  if (conditions && conditions.length > 0 && jumpTo) {
    return {
      elseJumpTo,
      statements: [
        {
          id: "statement-0",
          subconditions: conditions,
          jumpTo,
        },
      ],
      visibility
    };
  }

  if (!elseJumpTo && jumpTo) {
    return {
      elseJumpTo: jumpTo,
      statements: [],
      visibility
    };
  }

  return {
    elseJumpTo,
    statements: [],
    visibility
  };
};

export const getFigmaBlockGoalNode = (block: FigmaBlock) => {
  const { goalNode } = block;

  if (!goalNode) {
    return undefined;
  }

  if (typeof goalNode === "string") {
    return [goalNode];
  }

  return goalNode;
};

export const checkParticipantDevice = (allowedDevice: "any" | "mobile" | "desktop") => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (allowedDevice === "any") {
    return true;
  }

  if (allowedDevice === "mobile" && isMobile) {
    return true;
  }

  if (allowedDevice === "desktop" && !isMobile) {
    return true;
  }

  return false;
};
