import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import i18n from '../../../i18n/config';
import { AIBlock } from '../../../models/Test';
import { IAiChatResponse } from '../../../models/Response';
import { AiChatTags } from './ReportSections/AiChatTags';
import { ListWithSeparators } from '../../Common/Containers/ListWithSeparators';
import { AiInstruction } from './ReportSections/AiInstruction';
import { AIChatMessages } from './ReportSections/AIChatMessages';
import { ChatDTO } from '../../../Common/schema/Chats/ChatDTO';
import { formatDate } from '../../../i18n/utils';
import { useChatReport } from './useChatReport';
import { IMessageTag } from '../../../Common/schema/Chats/UpdateChatMessageDTO';
import { BTextAccent } from '../../Common/Text';
import { useTags } from './useTags';
import { TestBlockReportContext } from '../ReportContext';
import { AiChatFilterTags } from './ReportSections/AiChatFilterTags';
import TextDropdown from '../../TextDropdown';
import { MessageTag } from './Chat/MessageTag';

function AllConversationsItem() {
	const { t } = useTranslation();
	return <div className='py-[2px]'>{t("Show all conversations")}</div>;
}

export default function AiChatReportSummary(props: { responses: IAiChatResponse[] }) {
	const reportContext = useContext(TestBlockReportContext);
	const { t } = useTranslation();
	const testId = reportContext?.testId || '';
	const block = reportContext?.block as AIBlock;

	const { taggedChats } = useChatReport({ testId, block: block, chatIdsFilter: props.responses.map(r => r.chatId) });
	const tags = useTags({ testId, blockId: block?.blockId || '' });

	const [filterTags, setFilterTags] = useState<Record<number, boolean>>({});
	const hasFilterTags = Object.keys(filterTags).length > 0;

	const filterDropdownItems = [
		{ name: <AllConversationsItem />, value: '' },
		...(tags.list.data?.map(tag => ({ value: tag.id.toString(), name: <MessageTag colorClassName={tags.getTagStyle(tag.color)}>{tag.name}</MessageTag> })) || [])
	];

	const chatsWithFilteredMessages = taggedChats?.map(chat => {
		const messages = chat.messages?.filter((currentMessage, i) => {
			const isCurrentHasTag = currentMessage.tags?.some(t => filterTags[t.id]);
			const isNextHasTag = !!chat.messages?.[i + 1]?.tags?.some(t => filterTags[t.id]);
			return isCurrentHasTag || isNextHasTag;
		});
		return { ...chat, messages };
	}).filter(chat => !!chat.messages?.length);

	useEffect(() => {
		if (tags.list.data) {
			// remove tags that are not in the list from filterTags
			const existentTags = tags.list.data.reduce((acc, tag) => {
				acc[tag.id] = tag;;
				return acc;
			}, {} as Record<number, IMessageTag>);
			setFilterTags(filterTags => _.pickBy(filterTags, (value, key) => !!existentTags[+key]));
		}
	}, [tags.list.data])

	function onTagSelect(tag: IMessageTag) {
		setFilterTags(filterTags => ({ ...filterTags, [tag.id]: true }));
	}

	function onTagRemove(tag: IMessageTag) {
		setFilterTags(filterTags => _.omit(filterTags, tag.id));
	}

	return <div className='ai-chat-report ai-chat-report_mode_summary'>
		<div className="ai-instruction__header mb-2">
			<BTextAccent>{t("Question")}</BTextAccent>
		</div>
		<div className="ai-instruction__header mb-8">
			{block?.text || ''}
		</div>
		<AiInstruction className='mb-8' instruction={block?.instruction || ''} />

		<AiChatTags className='mb-10' filterTags={{}} onTagSelect={onTagSelect} chats={taggedChats} />

		{/* {hasFilterTags && <AiChatFilterTags filterTags={filterTags} onTagRemove={onTagRemove} />} */}

		<div className="ai-chat-report__chats grid grid-cols-3 sm:grid-cols-5 gap-x-6 mt-8 grid-rows-[auto_1fr]">
			<div className="ai-chat-report__chats-header col-start-1 col-span-3 sm:col-span-5 h-auto mb-8 self-start">
				<div className='flex flex-row items-center gap-4'>
					<BTextAccent>{t("Conversations")}</BTextAccent>

					<TextDropdown
						placeholder={<AllConversationsItem />}
						className='w-full sm:w-[240px]'
						items={filterDropdownItems}
						value={Object.keys(filterTags).map(id => id.toString())[0]}
						onChange={(value) => value ? setFilterTags({ [(tags.list.data?.find(t => t.id === +value)!).id]: true }) : setFilterTags({})}
					/>
				</div>
			</div>

			{!hasFilterTags && <div className='max-h-full col-start-1 col-span-3 sm:col-span-5'>
				{taggedChats?.map((chat, i) => <div key={chat.id} className={clsx("chat p-4 border border-1 border-gray-200 rounded-xl", { "mt-4": i > 0 })}>
					<AIChatMessages
						className={''}
						messages={chat.messages?.slice(1, -1) || []}
						showScrollPadding={false}
					/>
				</div>)}
			</div>}

			{/* Old layout with chats column on the left and current chat on the right */}
			{/* {!hasFilterTags && <div className='col-start-1 col-span-1'>
				<div className='ai-chat-report__chats-list flex flex-col gap-2 max-h-full overflow-auto'>
					{taggedChats?.map(chat => <AiChatListItem key={chat.id} chat={chat as any} isSelected={chat.id === currentChatId} onClick={() => setCurrentChatId(chat.id)} />)}
					<ScrollFade />
				</div>
			</div>}
			{!hasFilterTags && !!currentChat && <AIChatMessages className='col-start-2 col-span-2 sm:col-span-4 max-h-full' key={currentChat.id} messages={currentChat.messages || []} />} */}

			{hasFilterTags && <div className='col-start-1 col-span-3 sm:col-span-5 max-h-full self-stretch'>
				<ListWithSeparators indent={4}>
					{chatsWithFilteredMessages?.map((chat) => <AIChatMessages key={chat.id} messages={chat.messages || []} showDate showScrollPadding={false} />)}
				</ListWithSeparators>
				<ScrollFade />
			</div>}
		</div>
	</div>;
}

function AiChatListItem(props: { chat: ChatDTO, isSelected: boolean, onClick?: () => void }) {

	return <div className={clsx("ai-chat",
		"px-2 py-1 hover:bg-gray-100 rounded-full cursor-pointer transition-all duration-200 ease-in-out",
		"text-center w-fit-content",
		{
			"bg-gray-200": props.isSelected
		})}
		onClick={props.onClick}>
		{formatDate(props.chat.createdAt, i18n.language)}
	</div>;
}

function ScrollFade() {
	return <div className="sticky bottom-0 min-h-6 bg-gradient-to-t from-current text-white pointer-events-none"></div>;
}