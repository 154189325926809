import React, { useEffect, useState } from "react";
import "../../../tailwind.generated.css";

import MatrixTable from "./MatrixReportTable";

import { MatrixBlock, Block } from "../../../models/Test";

import lodash from "lodash";
import { IMatrixResponse } from "../../../models/Response";

import { useTranslation } from "react-i18next";

type Summary = Record<string, Record<string, number>>;

const MatrixReport = ({ block, responses }: { block: MatrixBlock & Block; responses: Array<IMatrixResponse> }) => {

  const { t } = useTranslation();

  const rows = [...block.rows];
  const columns = [...block.columns];

  const [summary, setSummary] = useState<Summary | undefined>();

  useEffect(() => {
    const newSummary: Summary = {};

    const rowIds = rows.map((row) => row.id);
    const columnIds = columns.map((column) => column.id);

    rows.forEach((row) => {
      columns.forEach((column) => {
        lodash.set(newSummary, [row.id, column.id], 0);
      });
    });

    responses.forEach((response) => {
      Object.keys(response.selectedOptions).forEach((rowId) => {
        if (!rowIds.includes(rowId)) return;
        response.selectedOptions[rowId].forEach((columnId) => {
          if (!columnIds.includes(columnId)) return;
          const currentValue = lodash.get(newSummary, [rowId, columnId]);
          lodash.set(newSummary, [rowId, columnId], currentValue + 1);
        });
      });
    });

    setSummary(newSummary);
  }, [block, responses]);

  return (
    <>
      <div>
        <div className="matrix-report__question">
          <span className="block caption">{t("Question")}</span>
          <div className="flex justify-start items-start gap-2 mb-4">
            <div className="text-gray-700">{block.text}</div>
          </div>
        </div>
        {summary && (
          <MatrixTable blockId={block.blockId} rows={rows} columns={columns} summary={summary} individual={false} />
        )}
      </div>
    </>
  );
};

export default MatrixReport;
