import React, { useState, useEffect } from "react";
import "../../../tailwind.generated.css";
import clsx from "clsx";

import Tabs, { TabItem } from "../../Tabs";

import MatrixTable from "./CardSortReportMatrixTable";
import ByCategory from "./ByCategory";
import ByCard from "./ByCard";

import _ from "lodash";
import { useTranslation } from "react-i18next";
import { CardSortBlock, BaseBlock } from "../../../models/Test";
import { ICardSortResponse } from "../../../models/Response";

import { generateId } from "../../../utils";

import { useCardSortData } from "../hooks/useCardSortData";

type CardSortReportProps = {
  testId: string;
  block: CardSortBlock & BaseBlock;
  responses: ICardSortResponse[];
  sharedByLink?: boolean;
  sharingToken?: string;
};

const CardSortReport = ({ testId, block, responses, sharedByLink, sharingToken }: CardSortReportProps) => {
  const { t } = useTranslation();

  const doNotRequireToSortAll = block.doNotRequireToSortAll;
  const cards = block.cards;
  const predefinedReportCategories = [...block.categories];

  const { reportCategories, cardsData, categoriesData, reportConfig } = useCardSortData({ testId, blockId: block.blockId, responses, predefinedCategories: predefinedReportCategories, cards, doNotRequireToSortAll, sharingToken });

  const blockConfig = reportConfig.reportConfig?.data?.blocks[block.blockId];
  const hiddenCategories = blockConfig?.hiddenCategories || [];


  async function createGroup(categories: string[], name: string) {
    // Identify if any of the provided category IDs are actually existing group IDs
    const existingGroupIds = blockConfig?.groups?.map((group: any) => group.id) || [];
    const groupIdsToDelete = categories.filter(categoryId => existingGroupIds.includes(categoryId));

    // Filter out groups with these IDs
    const filteredGroups = blockConfig?.groups?.filter((group: any) => !groupIdsToDelete.includes(group.id)) || [];

    const resultCategories = [];
    for (const categoryId of categories) {
      // Check if the category ID is an existing group ID
      const existingGroup = blockConfig?.groups?.find((group: { id: string, name: string, categories: string[] }) => group.id === categoryId);
      if (existingGroup) {
        resultCategories.push(...existingGroup.categories);
      } else {
        resultCategories.push(categoryId);
      }
    }

    // Create a new group
    const group = {
      id: generateId(),
      name: name,
      categories: resultCategories
    };

    // Add the new group to the filtered groups
    const newBlockConfig = blockConfig ? {
      ...blockConfig,
      groups: [...filteredGroups, group]
    } : {
      groups: [group]
    };

    // Update the block config
    return await reportConfig.updateBlockConfig.mutateAsync({ config: newBlockConfig, blockType: "cardsort" });
  }

  async function bulkCreateGroups(categoriesAndNames: { categories: string[], name: string }[]) {
    const groups = categoriesAndNames.map(({ categories, name }) => ({
      id: generateId(),
      name: name,
      categories: categories
    }));

    const newBlockConfig = blockConfig ? {
      ...blockConfig,
      groups: [...blockConfig.groups, ...groups]
    } : {
      groups: [...groups]
    };

    return await reportConfig.updateBlockConfig.mutateAsync({ config: newBlockConfig, blockType: "cardsort" });
  }

  async function deleteGroup(groupId: string) {
    const newBlockConfig = {
      ...blockConfig,
      groups: blockConfig.groups.filter((group: any) => group.id !== groupId),
    };
    return await reportConfig.updateBlockConfig.mutateAsync({ config: newBlockConfig, blockType: "cardsort" });
  }

  async function updateGroupName(groupId: string, name: string) {
    const newBlockConfig = {
      ...blockConfig,
      groups: blockConfig.groups.map((group: any) => {
        if (group.id === groupId) {
          return {
            ...group,
            name: name,
          };
        }
        return group;
      }),
    };
    return await reportConfig.updateBlockConfig.mutateAsync({ config: newBlockConfig, blockType: "cardsort" });
  }

  async function hideCategory(categoryId: string) {
    try {
      const newBlockConfig = {
        ...(blockConfig ?? {}),
        hiddenCategories: [...(blockConfig?.hiddenCategories ?? []), categoryId],
      };
      return await reportConfig.updateBlockConfig.mutateAsync({ config: newBlockConfig, blockType: "cardsort" });
    } catch (error) {
      return null;
    }
  }

  async function unhideCategory(categoryId: string) {
    try {
      const newBlockConfig = {
        ...blockConfig,
        hiddenCategories: (blockConfig.hiddenCategories || []).filter((id: string) => id !== categoryId),
      };
      return await reportConfig.updateBlockConfig.mutateAsync({ config: newBlockConfig, blockType: "cardsort" });
    } catch (error) {
      return null;
    }
  }

  if (reportConfig.reportConfig.isPending || !reportCategories || !cardsData || !categoriesData) {
    return null;
  }


  return (
    <>
        <div className="card-sort-report__question">
          <span className="block caption">{t("Task")}</span>
          <div className="flex justify-start items-start gap-2 mb-4">
            <div className="text-gray-700">{block.text}</div>
          </div>
        </div>
      <Tabs>
        <TabItem label={t("Matrix")}>
          <MatrixTable
            blockId={block.blockId}
            rows={cards}
            columns={reportCategories}
            hiddenCategories={hiddenCategories}
            cardsData={cardsData}
            individual={false}
            response={undefined}
            totalResponses={responses.length}
          />
        </TabItem>
        <TabItem label={t("Categories")}>
          <ByCategory
            testId={testId}
            blockId={block.blockId}
            categoriesData={categoriesData}
            cards={cards}
            categories={reportCategories}
            hiddenCategories={hiddenCategories}
            totalResponses={responses.length}
            createGroup={createGroup}
            bulkCreateGroups={bulkCreateGroups}
            deleteGroup={deleteGroup}
            updateGroupName={updateGroupName}
            hideCategory={hideCategory}
            unhideCategory={unhideCategory}
            isOpenSort={block.isOpenSort}
            sharedByLink={sharedByLink}
          />
        </TabItem>
        <TabItem label={t("Cards")}>
          <ByCard
            blockId={block.blockId}
            cardsData={cardsData}
            cards={cards}
            categories={reportCategories}
            hiddenCategories={hiddenCategories}
            totalResponses={responses.length}
          />
        </TabItem>
      </Tabs>
    </>
  );
};

export default CardSortReport;
