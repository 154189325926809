import React from "react";
import "../tailwind.generated.css";
import clsx from "clsx";

type SwitcherProps = {
  text?: string | JSX.Element;
  handler: (value: boolean) => void;
  caption?: string;
  isOn: boolean;
  disabled?: boolean;
  danger?: boolean;
  className?: string;
  large?: boolean;
};

const Switcher = ({ text, handler, caption = "", isOn, disabled = false, danger = false, className = "", large = false }: SwitcherProps) => {
  function onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    handler(!isOn);
  }

  return (
    <div className='switcher'>
      <div className={clsx("switcher__control flex justify-start items-center", className && className)}>
        <div
          className={clsx(
            "switcher__body rounded-full",
            large ? "p-[7px]" : "p-[4px]",
            large ? "w-10 h-6 min-w-10" : "w-[28px] h-4",
            isOn ? (danger ? "bg-red-600" : "bg-blue-600") : "bg-gray-500",
            disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
          )}
          onClick={onClick}
        >
          <div className={clsx("switcher__dot rounded-full bg-white",
            large ? "w-[10px] h-[10px]" : "w-[8px] h-[8px]",
            "transition-all duration-200",
            isOn && large && "ml-4",
            isOn && !large && "ml-3"
          )}></div>
        </div>
        {text && (
          <div className={clsx(large ? "ml-3" : "ml-2", disabled ? "text-gray-500" : "text-gray-800", "font-medium cursor-pointer text-sm")} onClick={onClick}>
            <span>{text}</span>
          </div>
        )}
      </div>
      {caption && <p className="switcher__label text-sm mt-2 text-gray-700">{caption}</p>}
    </div>
  );
};

export default Switcher;
