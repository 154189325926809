import clsx from 'clsx';
import React from 'react';

interface RadioProps {
	isOn: boolean;
	name: string;
	value?: string;
	label?: string | JSX.Element;
	onChange: (value: boolean) => void;
	className?: string;
	disabled?: boolean;
}

export default function Radio({
	isOn,
	name,
	value,
	label,
	onChange,
	className,
	disabled,
}: RadioProps) {
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.checked);
	};

	return (
		<label className={clsx("cursor-pointer flex items-center", className)}>
			<input
				type="radio"
				name={name}
				value={value}
				checked={isOn}
				onChange={handleInputChange}
				className="sr-only" // visually hidden but accessible to screen readers
				disabled={disabled}
			/>
			<div
				className={clsx(
					"relative border-1 flex items-center justify-center z-10 transition-all duration-75 ease-in rounded-full w-4 h-4 min-w-4 min-h-4 hover:border-gray-500",
					disabled && "opacity-50 cursor-not-allowed",
					isOn ? "bg-blue-600 border-transparent" : "border-gray-400 bg-white"
				)}
				role="radio"
				aria-checked={isOn}
				tabIndex={0}
			>
				{!!isOn && <div className='bg-white w-[6px] h-[6px] min-w-[6px] min-h-[6px] rounded-full'></div>}
			</div>
			{label && <span className="ml-2">{label}</span>}
		</label>
	);
}
