import React from 'react';
import { BTextAccent } from '../../../Common/Text';
import { ReportChatMessage } from '../Models/ReportChatMessage';
import { MessageTag } from './MessageTag';
import clsx from 'clsx';
import { IMessageTag } from '../../../../Common/schema/Chats/UpdateChatMessageDTO';
import { useTags } from '../useTags';

export interface ChatMessageBaseProps { message: ReportChatMessage, className?: string }

export function AssistantMessage(props: ChatMessageBaseProps) {

	return <div className={clsx("chat-message chat-message_mode_assistant", props.className)} id={props.message.id.toString()}>
		<div className='chat-message__content font-medium text-gray-700'>{props.message.text}</div>
	</div>;
}

type UserMessageProps = ChatMessageBaseProps & {
	onTagDelete?: (tag: IMessageTag) => void,
	tempTag?: { name: string, color: string };
	canDeleteTags: boolean;
}

export function UserMessage(props: UserMessageProps) {
	const tags = useTags({ testId: '', blockId: '' });

	return <div className={clsx("chat-message chat-message_mode_user ",
		// "bg-gray-200 rounded-lg p-3",
		props.className)} id={props.message.id.toString()}>
		<div className='chat-message__content text-gray-700'>
			{props.message.text}
		</div>
		{(!!props.message.tags?.length || !!props.tempTag) && <div className="chat-message__tags mt-2 flex flex-row flex-wrap gap-1">

			{props.message.tags?.map(tag => <MessageTag
				className='cursor-pointer'
				colorClassName={tags.getTagStyle(tag.color)}
				key={tag.id}
				withContextMenu={props.canDeleteTags}
				onDeleteClick={() => props.onTagDelete?.(tag)}>
				<span className='text-xs'>{tag.name}</span>
			</MessageTag>)}

			{!!props.tempTag && <MessageTag className='animate-pulse' colorClassName={tags.getTagStyle(props.tempTag.color)}>
				<span className='text-xs'>{props.tempTag.name}</span>
			</MessageTag>}
		</div>}
	</div>;
}