import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { BTextAccent } from '../../../Common/Text';
import { CollapsibleContainer } from '../../../Common/Containers/CollapsibleContainer';


export function AiInstruction(props: { instruction: string; className?: string; }) {
	const [collapsed, setCollapsed] = React.useState(true);
	const { t } = useTranslation();

	const minHeight = 24;
	const instructionTextRef = useRef<HTMLDivElement>(null);

	const [isTextHGTMinHeight, setIsTextHGTMinHeight] = useState(false);

	React.useEffect(() => {
		checkAndUpdateHeightState();
		window.addEventListener('resize', checkAndUpdateHeightState);

		function checkAndUpdateHeightState() {
			if (instructionTextRef.current) {
				const height = instructionTextRef.current.clientHeight;
				// isTextHGTMinHeight = height > minHeight;
				setIsTextHGTMinHeight(height > minHeight);
				if (height > minHeight) {
					setCollapsed(true);
				}
			}
		}

		return () => {
			window.removeEventListener('resize', checkAndUpdateHeightState);
		};
	}, [props.instruction, instructionTextRef.current, minHeight]);

	return <div className={clsx(`ai-instruction`, props.className)}>
		<div className="ai-instruction__header mb-2">
			<BTextAccent>{t("Instruction for AI")}</BTextAccent>
		</div>
		<CollapsibleContainer className='ai-instruction__container relative mb-2' minH='max-h-[1.5rem]' maxH="max-h-[20rem]"
			isCollapsed={collapsed}>
			<div className='ai-instruction__text' ref={instructionTextRef}>
				{props.instruction}
			</div>
			<div className="w-16 h-5 bg-gradient-to-r from-transparent to-white absolute right-0 bottom-0"></div>
		</CollapsibleContainer>

		{isTextHGTMinHeight && <a className="ai-instruction__link text-blue-500" onClick={() => setCollapsed(v => !v)} >
			{collapsed ? t("Show more") : t("Show less")}
		</a>}
	</div >;
}
