import React, { useState, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";

import Tabs from "./Tabs";
import { ClicksMapLayerTabs, tabsLabels } from "./ClicksMapLayerTabs";
import { ScreenStats } from "../FigmaReport/ScreenStats";
import Loader from "../../Loader";
import FirstClickmap from "../Clickmaps/FirstClickmap";

import { IconClose, IconFirstClick, IconDownload, IconClickarea } from "../../../icons";

import { IClickmap } from "../Clickmaps/STORAGE";
import { IFirstClickResponse } from "../../../models/Response";
import { IFirstClickStat } from "../models";

export interface IClickViewsProps {
	blockId: string;
	loading: boolean;
	clickmap: IClickmap;
	updateClickmapAreas: (areas: any[]) => void;
	responses: IFirstClickResponse[];
	imageUrl: string;
	title: string;
	statistic: IFirstClickStat;
	onClose: () => void;
}

function ClickViews(props: IClickViewsProps) {
	const { t } = useTranslation();
	const { clickmap } = props;

	const clickMapWrapperRef = useRef<HTMLDivElement>(null);

	const [activeTab, setActiveTab] = useState(tabsLabels.HEATMAP);

	const screenStats = [
		[t("Respondents"), props.statistic.respondents],
		[t("Average time"), `${props.statistic.averageResponseTime} ${t("s")}`],
		[t("Median time"), `${props.statistic.medianResponseTime} ${t("s")}`],
	];

	const tabs = Object.entries(tabsLabels).map(([, label]) => ({
		onClick: () => setActiveTab(label),
		isActive: activeTab === label,
		label: <Trans>{label}</Trans>,
	}));

	const downloadHeatmap = async () => {
		const heatmapCanvas = document.querySelector('.heatmap-canvas') as HTMLCanvasElement;
		
		const imageUrl = props.imageUrl;
	  
		if (heatmapCanvas && imageUrl) {
		  try {
			const response = await fetch(imageUrl);
			const imageBlob = await response.blob();
	
			const objectURL = URL.createObjectURL(imageBlob);
	  
			const image = new Image();
			image.src = objectURL;
			image.onload = () => {

			  const exportCanvas = document.createElement('canvas');
			  exportCanvas.width = heatmapCanvas.width;
			  exportCanvas.height = heatmapCanvas.height;
			  const ctx = exportCanvas.getContext('2d');
	
			  if (!ctx) return;
			  
			  ctx.drawImage(image, 0, 0, exportCanvas.width, exportCanvas.height);
			  ctx.drawImage(heatmapCanvas, 0, 0, exportCanvas.width, exportCanvas.height);
			  
			  exportCanvas.toBlob((blob) => {
				if (!blob) return;
				const downloadUrl = URL.createObjectURL(blob);
				const downloadLink = document.createElement('a');
				downloadLink.href = downloadUrl;
				downloadLink.download = `heatmap-${props.blockId}.png`;
				document.body.appendChild(downloadLink);
				downloadLink.click();
				document.body.removeChild(downloadLink);
				// Clean up the object URL
				URL.revokeObjectURL(downloadUrl);
			  }, 'image/png');
	
			  // Clean up the object URL for the image
			  URL.revokeObjectURL(objectURL);
			};
		  } catch (error) {
			console.error('Error fetching or processing image', error);
		  }
		} else {
		  console.error('Canvas element or image URL not found');
		}
	  };

	if (props.loading) {
		return (
			<div className="flex">
				<div className="flex-auto rounded-2xl bg-gray-800 p-6">
					<Tabs tabs={tabs} />
					<div className="p-6">
						<Loader />
					</div>
				</div>
			</div>
		);
	}
	
	return (
		<div className="clickviews-container">
			<div className="bg-gray-800 px-6 pt-2 w-full rounded-xl flex flex-col gap-4">
				<div className="clickviews__header flex items-center justify-between gap-2 mt-4 max-w-full w-full">
					<div className="flex items-center gap-2 max-w-[calc(100%_-_48px)]">
						<IconFirstClick className="w-6 h-6 fill-current shrink-0" />
						<div className="header3 text-white whitespace-nowrap truncate">{props.title}</div>
					</div>
					<IconClose className="w-6 h-6 fill-current text-white cursor-pointer hover:opacity-75 transition-opacity duration-75 shrink-0" onClick={() => props.onClose()} />
				</div>
				<Tabs tabs={tabs} />
				<div className="grid grid-cols-[1fr_230px]">
					<div className="clickviews__clickmap mt-4 flex-1 overflow-x-hidden overflow-y-hidden">
						<FirstClickmap
							clickmap={clickmap}
							wrapperRef={clickMapWrapperRef}
							onAreaAdded={props.updateClickmapAreas}
							imageUrl={props.imageUrl}
							clicks={props.responses}
							hideAreas={false}
						>
							{(withScale, getScaleRate) => (
								<ClicksMapLayerTabs
									width={props.clickmap.width}
									height={props.clickmap.height}
									clicks={props.responses}
									withScale={withScale}
									activeTab={activeTab}
									key={`clicks-${getScaleRate(props.clickmap.width)}`}
								/>
							)}
						</FirstClickmap>
					</div>

					<div className="clickviews__areas pl-4 flex flex-col gap-4">
						<ScreenStats stats={screenStats} className="text-white" />

						<div className="flex items-start cursor-pointer hover:opacity-75 transition-all duration-75 ease-in text-white">
              <IconDownload className="fill-current w-6 h-6" />
              <span className="ml-2 font-medium text-sm" onClick={downloadHeatmap}>{t("Download heatmap")}</span>
            </div>

						<div  className="flex flex-col gap-4">
							<div className="text-gray-500 font-medium flex items-center justify-start text-white">
								<IconClickarea className="fill-current mr-2 " width={24} height={24} />
								<span>{t("Click Areas")}</span>
							</div>
							<div className="text-sm text-white">
								{t("To analyze a specific area, draw a rectangle on the prototype.")}{" "}
								{t("Click on area to remove.")}
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}

export default ClickViews;
