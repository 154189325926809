import { ConditionType } from './ConditionType';
import { Operator } from './Operator';

export type LogicCondition = {
	id: string;
	condition: ConditionType;
	value: string;
	operator: Operator;
	valueSource?: string;
};

export type LogicStatement = {
	id: string;
	subconditions: LogicCondition[];
	jumpTo: string;
	visibilityType?: VisibilityLogicType;
};

export type Logic = {
	statements: LogicStatement[];
	visibility?: LogicStatement[];
	elseJumpTo: string;
	// Legacy
	/** @deprecated */
	conditions?: LogicCondition[];
	/** @deprecated */
	jumpTo?: string;
};

export enum VisibilityLogicType {
	show = 'show',
	hide = 'hide',
}