import React from "react";
import { figmaPluginUrl } from "../../../../utils/figma";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n/config";
import { getHelpCenterLink } from "../../../../utils/help";

const PluginInstructions = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="p-4 my-4 rounded-lg bg-blue-100 text-sm">
        <div className="font-medium">{t("How to import your Figma prototype?")}</div>
        <div className="flex items-center mt-2">
          <span>
            {t("1. Open")}{" "}
            <a href={figmaPluginUrl} className="text-blue-600" target="_blank" rel="noreferrer">
              {t("Pathway Figma plugin ↗")}
            </a>{" "}
            {t("inside your Figma file with the prototype.")}
          </span>
        </div>
        <div className="flex items-center mt-2">
          <span>{t("2. Select your starting frame")}</span>
        </div>
        <span className="block mt-2">{t("3. Click “Import”. You’ll get a code — paste it below.")}</span>
        <span className="mt-2 block">
          <a
            href={getHelpCenterLink(t("/articles/8935080-how-to-prepare-a-figma-prototype-for-import-via-a-plugin"))}
            type="default"
            rel="noreferrer"
            target="_blank"
          >
            {t("Tips: how to prepare your Figma prototype? ↗")}
          </a>
        </span>
      </div>
    </>
  );
};

export default PluginInstructions;
