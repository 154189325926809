import React from "react";
import "../tailwind.generated.css";
import clsx from "clsx";

import { IconDropdown } from "../icons";

import Dropdown, { DropdownOption } from "./Dropdown";
import Loader from "./Loader";

const TextDropdown = ({
  title = "",
  small,
  large = false,
  items,
  onChange,
  value,
  placeholder,
  className,
  isLoadingValue = false,
  light,
  position = "left",
  parentTestId
}: {
  title?: string;
  small?: boolean;
  large?: boolean;
  position?: string;
  items: DropdownOption[];
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string | JSX.Element;
  className?: string;
  isLoadingValue?: boolean;
  light?: boolean;
  parentTestId?: string;
}) => {
  const getItemNamebyValue = (value: string | undefined) => {
    const selectedItem = items.find((item) => item.value === value);
    const selectedItemName = selectedItem?.name;
    if (!selectedItemName) {
      return placeholder;
    } else {
      return selectedItemName;
    }
  };

  const isEmpty = value === "" || value === null || value === undefined;

  return (
    <div className={className}>
      {title && <div className="caption">{title}</div>}
      <Dropdown
        parentTestId={parentTestId && `${parentTestId}.dropdown`}
        type="single"
        items={items}
        handler={onChange}
        value={value ? value : undefined}
        light={light}
      >
        <div 
          className={clsx("flex items-center justify-between default-select", small ? "default-input-small" : "default-input")}
          data-testid={parentTestId && `${parentTestId}.dropdown`}
        >
          <div
            className={clsx(
              items.length === 0 && "cursor-not-allowed",
              large && "text-xl",
              small && "text-sm",
              !large && !small && "text-base",
              isEmpty && "text-gray-500",
              items.length !== 0 && "cursor-pointer",
              "min-w-0 overflow-hidden text-ellipsis whitespace-nowrap"
            )}
          >
            {isLoadingValue ? <Loader className='py-[9px]' /> : getItemNamebyValue(value)}
          </div>
          <IconDropdown
            className="ml-1 cursor-pointer text-gray-800 fill-current shrink-0 w-5 h-5"
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default TextDropdown;
