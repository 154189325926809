import React, { useEffect, useState } from "react";
import "../../../tailwind.generated.css";
import clsx from "clsx";

import _ from "lodash";

type MatrixReportTableProps =
  | {
      // for summary
      blockId: string;
      rows: { id: string; value: string }[];
      columns: { id: string; value: string }[];
      summary: Record<string, Record<string, number>>;
      individual: false;
    }
  | {
      // for responses
      blockId: string;
      rows: { id: string; value: string }[];
      columns: { id: string; value: string }[];
      individual: true;
      response: Record<string, string[]>; // for individual
    };

const MatrixReportTable = ({ blockId, rows, columns, individual, ...rest }: MatrixReportTableProps) => {
  const [withScroll, setWithScroll] = useState<boolean | null>(null);

  useEffect(() => {
    const tableElement = document.getElementById(`matrixTableWrapper-${blockId}`);
    if (tableElement) {
      const withScroll = tableElement.scrollWidth > tableElement.clientWidth;
      setWithScroll(withScroll);
    }
  }, []);

  return (
    <div className="relative max-w-full w-full">
      {withScroll && <div className="absolute w-4 h-full right-0 top-0 bg-gradient-to-r from-transparent to-white z-30"></div>}
      <div className={clsx("relative overflow-x-auto", withScroll && "pr-4")} id={`matrixTableWrapper-${blockId}`}>
        <table className="border-separate w-full">
          <thead>
            <tr>
              <th className="sticky text-center px-2 py-4 left-0"></th>
              {columns.map((column) => (
                <th className="text-center px-2 py-4 relative font-normal text-xs text-gray-700" key={column.id}>
                  <div className={clsx("min-w-24")}>{column.value}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody id="matrixTbody">
            {rows.map((row) => {
              if (!individual && "summary" in rest) {
                const summary = rest.summary;
                const rowQuantityData = Object.keys(summary[row.id])
                  .map((columnId) => summary[row.id][columnId])
                  .sort((a, b) => b - a);
                const sortedAppearance = _.sortedUniq(rowQuantityData);
                const sum = _.sum(rowQuantityData);
                const top1value = sortedAppearance[0];
                const top2value = sortedAppearance[1];
                const top3value = sortedAppearance[2];
                const getCellColor = (cellValue: number) => {
                  if (cellValue === 0 || cellValue === undefined || individual) {
                    return "bg-gray-100";
                  }
                  if (cellValue === top1value) {
                    return "bg-fuchsia-300";
                  }
                  if (cellValue === top2value) {
                    return "bg-fuchsia-200";
                  }
                  if (cellValue === top3value) {
                    return "bg-fuchsia-100";
                  }
                  return "bg-gray-100";
                };
                return (
                  <tr key={row.id} className="h-12">
                    <td
                      className="sticky px-3 align-middle text-xs text-gray-700 left-0 bg-white text-ellipsis w-40 overflow-hidden z-[100]"
                      style={{ minWidth: "160px" }}
                    >
                      {row.value}
                    </td>
                    {columns.map((column) => {
                      const cellValue = summary[row.id][column.id];
                      const percentage = Math.round((cellValue / sum) * 100) + "%";
                      return (
                        <td
                          className={clsx("px-2 py-4 text-center min-w-5xs h-12 rounded-md", getCellColor(cellValue || 0))}
                          key={column.id}
                        >
                          {cellValue ? (
                            <span className="text-fuchsia-900">
                              <span className="font-medium">{percentage}</span>
                              <span className="opacity-50">&nbsp;({cellValue})</span>
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              } else {
                return (
                  <tr key={row.id} className="h-12">
                    <td
                      className="sticky px-3 align-middle text-sm left-0 bg-white text-ellipsis w-40 overflow-hidden"
                      style={{ minWidth: "160px" }}
                    >
                      {row.value}
                    </td>
                    {columns.map((column) => {
                      return (
                        <td className={clsx("px-2 py-4 text-center min-w-5xs h-12 text-xl rounded-md bg-fuchsia-100")} key={column.id}>
                          {"response" in rest &&
                          rest.response &&
                          rest.response[row.id] &&
                          rest.response[row.id].find((optionInTheRow) => optionInTheRow === column.id)
                            ? "✓"
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MatrixReportTable;
