import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import Button from "../../../../../Button";
import ImageWithZoom from "../../../../../ImageWithZoom";

import TestBranding from "../TestBranding";

import { locale, interfaceText } from "../../../../../../helpers";
import { Block, BlockType } from "../../../../../../models/Test";

import useIsMobile from "../../hooks/useIsMobile";

import { AnimatePresence, motion } from "framer-motion";

import { addBlockIdToUrl } from "../../../../../../helpers";

export interface IContentWrapperProps {
  hasButton: boolean;
  content: React.ReactElement;
  nextStepOnClick?: () => void;
  isValidated: boolean;
  block: Block;
}

export function ContentWrapper(props: IContentWrapperProps) {
  const { block } = props;

  const isWide = block.type === BlockType.matrix;

  const isMobile = useIsMobile();

  // const isMobileLayout = isMobile || hasImage;

  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    return () => {
      setIsImageLoaded(false);
    }
  }, [block.blockId]);

  const hasImage = "image" in block && !!block.image && block.image.length > 0;
  const isOptional = "isOptional" in block && block.isOptional;

  useEffect(() => {
    const checkOverflow = () => {
      if (contentWrapperRef.current) {
        const isContentOverflowing = contentWrapperRef.current.scrollHeight > contentWrapperRef.current.clientHeight;
        setIsOverflowing(isContentOverflowing);
      }
    };
    // todo: fix this logic - setTimeout is more like a hack
    setTimeout(checkOverflow, 100);
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  const useMobileLayout = isMobile || hasImage;
  const submitBlock = <SubmitBlock
    className={useMobileLayout ? "w-full p-6 border-t-1 border-gray-300 flex justify-end z-[1000]" : "self-start"}
    onClick={props.nextStepOnClick}
    disabled={!props.isValidated && !isOptional}
    isLoading={hasImage && !isImageLoaded}
  />

  if (!hasImage) {
    return (
      <AnimatePresence>
        <div
          id={`content-wrapper-${block.blockId}`}
          className={clsx("content-wrapper h-full w-full flex flex-col", "overflow-y-auto")}
        >
          <TestBranding className="px-6 pt-6" />
          <motion.div
            key={block.blockId}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className={clsx(
              "content-wrapper__centered flex flex-col items-center p-6 flex-1 w-full",
              isOverflowing ? "justify-start" : "justify-center",
            )}
          >
            {!isMobile && <div className={clsx("content-wrapper__content-placeholder",
              "flex flex-col gap-5 w-full", isWide ? "max-w-lg" : "max-w-md")}>
              {props.content}
              {submitBlock}
            </div>}

            {isMobile && <div className={clsx("content-wrapper__content-placeholder",
              "flex flex-col gap-5 w-full", "flex-1")}>
              {props.content}
            </div>}
          </motion.div>
        </div>
        {isMobile && submitBlock}
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence>
      {isMobile ? (
        <motion.div
          id={`content-wrapper-${block.blockId}`}
          key={block.blockId}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="content-wrapper flex flex-col w-full min-w-full h-full flex-1 overflow-y-auto"
        >
          <div
            className={clsx("content-wrapper__scrollable h-full w-full flex flex-col", "overflow-y-auto")}
          >
            <TestBranding className="content-wrapper__branding p-6" />

            <div className="content-wrapper__image flex justify-center items-center w-full flex-shrink-1 flex-grow-1 px-6 py-4">
              <div className="overflow-hidden">
                <ImageWithZoom
                  imageSrc={addBlockIdToUrl((block as any).image, block.blockId)}
                  className="h-full w-auto object-contain max-h-[40dvh]"
                  onLoad={() => {
                    setIsImageLoaded(true);
                  }}
                />
              </div>
            </div>

            <motion.div
              ref={contentWrapperRef}
              className="content-wrapper__content content-wrapper__content_sidebar flex flex-col items-center h-full_ flex-0 justify-start z-50"
            >
              <div className="flex-1 flex flex-col gap-4 w-full p-6 overflow-y-auto">
                {props.content}
              </div>
            </motion.div>
          </div>

          {submitBlock}
        </motion.div>
      ) : (
        <motion.div
          id={`content-wrapper-${block.blockId}`}
          key={block.blockId}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="content-wrapper flex flex-row w-full min-w-full h-full flex-1 flex-grow min-h-0"
        >

          <motion.div
            ref={contentWrapperRef}
            className="content-wrapper__content content-wrapper__content_sidebar flex flex-col items-center h-full flex-0 justify-start z-50 shadow-xs min-w-[360px] max-w-[440px]"
          >
            <div className="flex flex-col gap-4 w-full p-6 flex-1 overflow-y-auto">
              <TestBranding />
              {props.content}
            </div>
            {submitBlock}
          </motion.div>

          <div className="content-wrapper__image flex justify-center items-center w-full flex-shrink-1 flex-grow-1 bg-gray-100 p-8 xl:p-16">
            <div className="overflow-hidden">
              <ImageWithZoom
                imageSrc={addBlockIdToUrl((block as any).image, block.blockId)}
                className="h-full w-auto object-contain max-h-[70dvh]"
                onLoad={() => {
                  setIsImageLoaded(true);
                }}
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}


interface SubmitBlockProps {
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
}

function SubmitBlock(props: SubmitBlockProps) {
  return (
    <div className={clsx("submit-block", props.className)}>
      <Button
        id="nextButton"
        type="primary"
        name={(interfaceText.test as any)[locale()].nextButton}
        handler={props.onClick}
        large
        disabled={props.disabled}
        isLoading={props.isLoading}
      />
    </div>
  );
}