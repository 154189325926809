import React, { useState, useEffect } from "react";
import clsx from "clsx";
import _ from "lodash";
import "../../../../../tailwind.generated.css";

import { IconCheck } from "../../../../../icons";

import Input from "./Input";

import { locale, interfaceText } from "../../../../../helpers";
import { IOptionReply } from '../../../../../models/Test';
import CheckBox from "../../../../Common/Checkbox";
import Radio from "../../../../Radio";

type ReplyType = "single" | "multi";

export interface ISelectProps {
  type: ReplyType;
  data: IOptionReply[];
  handler: (key: string, value: any) => void;
  isRandomized: boolean;
  withOther: boolean;
  selectedOptions: { id: string, value: string }[];
  hasChoiceLimit?: boolean;
  choiceLimit?: number;
}

const Select = ({
  type,
  data,
  handler,
  isRandomized,
  withOther,
  selectedOptions,
  ...props
}: ISelectProps) => {
  const [options, setOptions] = useState<IOptionReply[] | null>(null);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherValue, setOtherValue] = useState<string | null>(null);

  const isMobile = window.screen.width <= 480;
  const otherPlaceholder = (interfaceText.test as any)[locale()].otherPlaceholder;

  useEffect(() => {
    setOptions(isRandomized ? getShuffledOptions(data) : data);
  }, [data, isRandomized]);

  useEffect(() => {
    if (otherValue === null) return;
    if (otherValue.length > 0) {
      const option = { id: "other", value: otherValue };
      addSelectedOption(option);
    } else {
      removeSelectedOption("other");
    }
  }, [otherValue]);

  function isOptionSelected(optionId: string) {
    if (!selectedOptions) {
      return false;
    }
    return selectedOptions.some((option) => option.id === optionId);
  }

  function handleSelect(optionId: string) {
    if (withOther && showOtherInput && type === "single") {
      setShowOtherInput(false);
    }

    if (isOptionSelected(optionId)) {
      removeSelectedOption(optionId);
      return;
    }

    if (isSelectedOptionsLimitReached()) return;

    const chosenItem = {
      id: optionId,
      value: options?.find((option) => option.id === optionId)?.replyValue as string,
    };

    addSelectedOption(chosenItem);
  }

  function handleOtherSelect() {
    if (isSelectedOptionsLimitReached()) return;
    setShowOtherInput(true);
  }

  function addSelectedOption(chosenItem: { id: string, value: string }) {
    let newSelectedOptions = null as (typeof chosenItem)[] | null;
    switch (type) {
      case "single":
        newSelectedOptions = [chosenItem];
        break;
      case "multi":
        newSelectedOptions = [...selectedOptions.filter((option) => option.id !== chosenItem.id), chosenItem];
        break;
      default:
        break;
    }
    handler("selectedOptions", newSelectedOptions);
  }

  function removeSelectedOption(optionId: string) {
    handler("selectedOptions", [...selectedOptions.filter((option) => option.id !== optionId)]);
  }

  function handleOtherValueChange(value: string) {
    setOtherValue(value);
  }

  function onOtherInputBlur(value: string) {
    if (value.length === 0) {
      setShowOtherInput(false);
    }
  }

  function isSelectedOptionsLimitReached() {
    return type === 'multi' && props.hasChoiceLimit && selectedOptions.length >= props.choiceLimit!;
  }

  return (
    <>
      {options && options.map((item) => <ChoiceOption
        key={item.id}
        value={item.replyValue}
        isSelected={isOptionSelected(item.id)}
        onClick={() => handleSelect(item.id)}
        withCheckMark={type === "multi"}
      />)}

      {withOther && <ChoiceOption
        className={clsx({ "hover:border-gray-400": !isMobile })}
        key={'other'}
        value={showOtherInput ?
          <Input
            id="other-input"
            placeholder={otherPlaceholder}
            initialValue={''}
            handler={(_, value) => handleOtherValueChange(value)}
            useCustomClassName={true}
            className={"w-full p-0 border-none transition-all duration-75 ease-in"}
            designConfig={undefined}
            singleLine={false}
            initialRows={1}
            onBlur={(e) => onOtherInputBlur(e.target.value)}
          /> :
          (interfaceText.test as any)[locale()].other
        }
        isSelected={isOptionSelected("other")}
        onClick={handleOtherSelect}
        withCheckMark={type === "multi"}
      />}

      {type === "multi" && (
        <div className="mt-4 text-sm text-gray-700">
          {!props.hasChoiceLimit && (interfaceText.test as any)[locale()].multipleChoiceTip}
          {!!props.hasChoiceLimit && (interfaceText.test as any)[locale()].multipleChoiceWithLimitTip.replace('{{count}}', props.choiceLimit!.toString())}
        </div>
      )}
    </>
  );
};

export default Select;


function ChoiceOption(props: { className?: string, value: string, isSelected: boolean, withCheckMark?: boolean, onClick: () => void }) {
  return <div
    className={clsx('w-full py-2 px-4 text-lg mb-3 rounded-md cursor-pointer transition-all duration-75 ease-in flex items-center gap-3',
      {
        "border-1 ring-1 ring-blue-600 border-blue-600": props.isSelected,
        "border-gray-200 border-1 hover:border-gray-400": !props.isSelected
      },
      props.className
    )}
    onClick={props.onClick}
  >
    {props.withCheckMark ? <CheckBox
      value={props.isSelected}
        onChange={props.onClick}
      /> : <Radio
        name={props.value}
        isOn={props.isSelected}
        onChange={props.onClick}
      />}
    {props.value}
  </div>
}

function getShuffledOptions(options: IOptionReply[]) {
  const optionsWithoutFixedPosition = options.filter((option) => typeof option.fixedPosition !== 'number');
  const optionsWithFixedPosition = options.filter((option) => typeof option.fixedPosition === 'number');
  // shuffle options without fixed position and insert options with fixed position on their places
  const shuffledOptions = _.shuffle(optionsWithoutFixedPosition);
  optionsWithFixedPosition.forEach((option) => {
    shuffledOptions.splice(option.fixedPosition as number, 0, option);
  });

  return shuffledOptions;
}