import React, { useEffect, useState, useCallback, useContext } from "react";
import _ from "lodash";
import clsx from "clsx";

import { IconAI, IconCheck, IconCheckMark, IconForward } from "../../../icons";

import Modal from "../../Modal";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";

import { useSuggestCategoriesForMerge } from "../hooks/useSuggestCategoriesForMerge";

import Button from "../../Button";
import Tooltip from "../../Tooltip";
import { TestBlockReportContext } from '../ReportContext';



type SuggestionProps = {
  suggestion: {
    name: string;
    ids: string[];
  },
  findCategoryName: (id: string) => string | undefined;
  createGroup: (categories: string[], name: string) => Promise<any>;
}

const Suggestion: React.FC<SuggestionProps> = ({ suggestion, findCategoryName, createGroup }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [isGroupCreated, setIsGroupCreated] = useState(false);

  const includedCategoriesNames = suggestion.ids.map((id: string) => findCategoryName(id));
  const visibleCategoriesNames = showAll ? includedCategoriesNames : includedCategoriesNames.slice(0, 10);

  return (
    <div className="grid grid-cols-[240px_24px_1fr_auto] gap-6 items-start py-4">
      <div>
        <div className="flex flex-wrap gap-1">
          {visibleCategoriesNames.map((name: string | undefined, index: number) => (
            <div key={index} className="text-xs text-gray-700 border-1 border-gray-400 rounded-full px-1">{name}</div>
          ))}
        </div>
        {includedCategoriesNames.length > 10 && (
          <button
            className="text-xs text-blue-600 mt-4"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? t("Hide all categories") : `${t("Show all")} (${includedCategoriesNames.length})`}
          </button>
        )}
      </div>
      <div className="w-24 justify-self-auto">
        <IconForward className="fill-current text-gray-500 w-6 h-6" />
      </div>
      <div className="font-medium flex items-center h-auto">
        <IconAI className="fill-current text-blue-400 mr-2 shrink-0 w-5 h-5" />
        <span className="text-lg">{suggestion.name}</span>
      </div>
      <div>
        <Button
          extrasmall
          type="primary"
          name={t("Merge")}
          handler={() => {
            setIsCreatingGroup(true);
            createGroup(suggestion.ids, suggestion.name).then(() => {
              setIsCreatingGroup(false);
              setIsGroupCreated(true);
            });
          }}
          disabled={isGroupCreated || isCreatingGroup}
        />
      </div>
    </div>
  );
}



type AutoMergeSuggestionsProps = {
  result: any,
  isLoading: boolean,
  error: string | null,
  categories: {
    id: string;
    value: string | undefined;
    isGroup?: boolean;
    isCustom?: boolean;
  }[];
  createGroup: (categories: string[], name: string) => Promise<any>;
};

const AutoMergeSuggestions: React.FC<AutoMergeSuggestionsProps> = ({ result, isLoading, error, categories, createGroup }) => {

  const findCategoryName = useCallback((id: string) => {
    const category = categories.find(category => category.id === id);
    return category?.value;
  }, []);

  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center my-16">
        <Loader />
        <span className="block mt-4 text-gray-700 text-center">{t("Please wait while some magic is happening in the background...")}</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-600">{t("Something went wrong. Please, try again.")}</div>
    );
  }

  if (!result || result.length === 0 || !Array.isArray(result)) {
    return (
      <div className="text-gray-600">{t("There are no suggestions right now.")}</div>
    );
  }


  return (
    <div className="">
      <div className="mb-4">{t("We found some categories, which look very similar. Here are our suggestions on how to merge them:")}</div>
      {result.map((suggest, i) => (
        <div className={clsx({ "border-b border-gray-300 border-b-1": i < result.length - 1 })} key={i}>
          <Suggestion suggestion={suggest} findCategoryName={findCategoryName} createGroup={createGroup} />
        </div>
      ))}
    </div>
  );
}

type AutoMergeProps = {
  categoriesData: {
    [categoryId: string]: {
      [cardId: string]: number;
    };
  };
  cards: {
    id: string;
    value: string;
    description: string | null | undefined;
    image: string | null | undefined;
  }[];
  categories: {
    id: string;
    value: string | undefined;
    isGroup?: boolean;
    isCustom?: boolean;
  }[];
  createGroup: (categories: string[], name: string) => Promise<any>;
  bulkCreateGroups: (categoriesAndNames: { categories: string[], name: string }[]) => Promise<any>;
};

const AutoMerge: React.FC<AutoMergeProps> = ({ categoriesData, cards, categories, createGroup, bulkCreateGroups }) => {

  const { t } = useTranslation();

  const [isMergeSuggestionsModalOpen, setIsMergeSuggestionsModalOpen] = useState<boolean>(false);

  const reportTestBlockContext = useContext(TestBlockReportContext);
  const { result, suggestCategoriesForMerge, isLoading, error } = useSuggestCategoriesForMerge({
    testId: reportTestBlockContext?.testId || '',
    blockId: reportTestBlockContext?.block.blockId || '',
    categories: categories.filter(category => category.isCustom === true).map((category) => { return { name: category.value || '', id: category.id } })
  });

  useEffect(() => {
    if (isMergeSuggestionsModalOpen) {
      suggestCategoriesForMerge();
    }
  }, [isMergeSuggestionsModalOpen]);

  return (
    <>
      <div className="flex items-center font-medium text-sm">
        <Tooltip
          anchorClassName="fit-content inline-block mt-6 mb-4"
          content={<div className="w-64 text-xs">{t("AI merging is not available for more than 300 categories")}</div>}
          showOnlyIf={categories.length > 300}
        >
          <Button
            extrasmall
            type="secondary"
            name={
              <span className="flex items-center">
                {t("Merge similar categories")}
                <span className="px-1 py-[2px] bg-blue-300 text-blue-800 text-xs leading-none font-medium ml-[6px] rounded-full">
                  {t("Beta")}
                </span>
              </span>
            }
            icon={<IconAI className="w-4 h-4 mr-2 text-gray-600" />}
            handler={() => {
              setIsMergeSuggestionsModalOpen(true);
            }}
            disabled={categories.length > 300}
          />
        </Tooltip>
      </div>
      <Modal isOpen={isMergeSuggestionsModalOpen} setIsOpen={setIsMergeSuggestionsModalOpen} width="720px">
        <div className="p-6">
          <div className="flex text-2xl mb-4 font-medium">{t("Auto merge categories")}</div>
          {/* {categoriesForAi?.length > 500 && <Banner type="info" header={t("We can analyze up to 500 categories")} text={t("Please note that currently AI can analyze up to 500 categories at once.")} className="my-4" />} */}
          <div className="text-sm mt-4">
            <AutoMergeSuggestions
              result={result}
              isLoading={isLoading}
              error={error}
              categories={categories}
              createGroup={createGroup}
            />
          </div>
        </div>
      </Modal>
    </>
  );

}

export default AutoMerge;