import React, { useEffect, useState, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import parse from "html-react-parser";

import { ContentWrapper } from '../ContentBlock/ContentWrapper';

import { interfaceText, locale } from '../../../../../../helpers';

import { AIBlock, AssistantAiChatMessage } from '../../../../../../models/Test';
import useAIChat from '../../hooks/useAIChat';
import { useTranscription } from '../../hooks/useTranscription';

import _ from 'lodash';
import Loader from '../../../../../Loader';
import { ChatStatus } from '../../../../../../Common/schema/Chats/CreateChatRequestDTO';

import useIsMobile from '../../hooks/useIsMobile';

import { IconAI, IconForward, IconMic } from '../../../../../../icons';
import AudioRecorder from './AudioRecorder';

import { AnimatePresence, motion } from 'framer-motion';

import AIOpenQuestionInput from './AIOpenQuestionInput';
import Button from '../../../../../Button';


export interface AiOpenQuestionProps {
  block: AIBlock;
  testId: string;
  blockId: string;
  answerId: string;
  isPreview: boolean;
  isOptional: boolean | undefined;
  currentStep: number;
  totalSteps: number;
  addBlockIdToImgUrl: (url: string | undefined, blockId: string) => any;
  onChatComplete: (chatId: number | null) => void;
}

export default function AiOpenQuestion(props: AiOpenQuestionProps) {
  const [message, setMessage] = useState<string>('');
  const {
    chat,
    sendMessage,
    isAIMessageLoading,
    createChat,
    skipChat
  } = useAIChat({ testId: props.testId, answerId: props.answerId, blockId: props.blockId, isPreview: props.isPreview });

  const transcription = useTranscription();

  const isMobile = useIsMobile();
  const isMobileLayout = isMobile || props.block.image;

  const startMessage = useMemo(() => new AssistantAiChatMessage(props.block.text), [props.block.text]);
  const [currentMessage, setCurrentMessage] = useState<AssistantAiChatMessage | null>(startMessage);

  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    if (chat && chat.messages.length) {
      const assistantMessages = chat.messages.filter(m => !m.isUser) as AssistantAiChatMessage[];
      setCurrentMessage(_.last(assistantMessages) || startMessage);
    }
  }, [chat, startMessage]);

  useEffect(() => {
    if (chat?.chat.status === ChatStatus.Completed) {
      props.onChatComplete(chat.chat.id);
    }
  }, [props.onChatComplete, chat?.chat.status, chat?.chat.id]);

  async function submitMessage(text: string) {
    if (!chat) {
      await createChat();
    }
    sendMessage(text);
    setMessage('');
  }

  async function onSubmit() {
    if (props.isOptional && !message) {
      if (!chat?.messages?.filter(m => m.isUser).length) {
        props.onChatComplete(null);
      } else {
        await skipChat();
        props.onChatComplete(chat.chat.id);
      }
    } else {
      await submitMessage(message);
    }
  }

    const onAudioSubmit = async (audioBlob: Blob) => {
      console.log('audioBlob', audioBlob);
      if (!audioBlob) {
        return;
      }
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio.wav');
    const transcriptionText = await transcription.mutateAsync(formData);
    console.log(transcriptionText);
    submitMessage(transcriptionText);
  }

  const questionText = currentMessage?.text;

  const sendingEmptyReplyWhenOptional = props.isOptional && !message;

  const isValidated = sendingEmptyReplyWhenOptional || !!message;

  console.log("isRecording", isRecording);

  return (
    <ContentWrapper
      isValidated={isValidated}
      hasButton={false}
      block={props.block}
      content={
        <>
          {isAIMessageLoading ? (
            <Loader />
          ) : (
            <motion.div
              key={props.block.blockId}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              className={clsx("ai-question__container w-full flex flex-col flex-1")}
            >
              <div
                className={clsx(
                  "ai-question__question w-full flex flex-col items-start flex-1 gap-5",
                  isMobileLayout ? "justify-between" : "justify-start"
                )}
              >
                <div className="ai-question__input w-full flex flex-col gap-4">
                  {questionText && <span className="text-lg font-medium">{parse(questionText)}</span>}
                  <AIOpenQuestionInput disabled={isRecording} onChange={(value: string) => setMessage(value)} />
                </div>
                <motion.div className={clsx("ai-question__submit flex items-center w-full")}>
                    {!message && <motion.div
                    className={clsx((isMobileLayout || props.isOptional) ? "order-1" : "order-2", isRecording && "w-full")}
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0, opacity: 0 }}
                    ><AudioRecorder setStartRecording={() => setIsRecording(true)} setEndRecording={() => setIsRecording(false)} onAudioSubmit={onAudioSubmit} /></motion.div>}
                    <AnimatePresence>
                    {(message || props.isOptional) && !isRecording && (
                        <motion.div
                          initial={{ scale: 0.95, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      className={clsx("w-full flex flex-1", isMobileLayout ? "justify-end origin-right" : "justify-start origin-left", props.isOptional && isMobileLayout && "order-2")}
                    >
                      <Button
                        large
                        handler={() => onSubmit()}
                        name={interfaceText.test[locale() as keyof typeof interfaceText.test].nextButton}
                      />
                    </motion.div>)}
                  </AnimatePresence>
                </motion.div>
              </div>
            </motion.div>
          )}
        </>
      }
    />
  );
}