import React from "react";
import { motion } from "framer-motion";
import useIsMobile from "../hooks/useIsMobile";

import { IconLeft, IconUp } from "../../../../../icons";

interface ShowTaskButtonProps {
  openTaskModal: () => void;
  buttonName: string;
}

const ShowTaskButton: React.FC<ShowTaskButtonProps> = ({ openTaskModal, buttonName }) => {

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5, duration: 0.2 }}
      whileHover={{ opacity: 0.75, transition: { duration: 0.2 } }}
      className={`pl-[4px] pr-[12px] py-[4px] h-12 absolute z-[99] rounded-r-lg shadow-[0px_2px_4px_3px_rgba(0,0,0,0.1)] bg-white opacity-[80%] backdrop-blur-lg flex items-center space-x-1 cursor-pointer hover:opacity-50 ${
        "top-6"
      }`}
      onClick={openTaskModal}
    >
        <IconLeft width={24} height={24} className="fill-current text-gray-800" />

      <motion.span
        initial={{ opacity: 1, x: 0 }}
        animate={{ opacity: 0, x: -10, display: 'none' }}
        transition={{ delay: 2, duration: 0.2 }}
       className="text-gray-800 text-sm font-medium h-8 flex items-center">{buttonName}
       </motion.span>
    </motion.div>
  );
};

export default ShowTaskButton;