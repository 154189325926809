import React, { useState, useRef, useEffect, useCallback } from 'react';
import { IconMic, IconForward, IconTrash, IconClose, IconError } from '../../../../../../icons';

import clsx from 'clsx';

import { useTranscription } from '../../hooks/useTranscription';
import Tooltip from '../../../../../Tooltip';
import { useTranslation } from 'react-i18next';

import Loader from '../../../../../Loader';

import { AnimatePresence, motion } from 'framer-motion';

const AudioRecorder = ({ className, setStartRecording, setEndRecording, onAudioSubmit }: { className?: string, setStartRecording: () => void, setEndRecording: () => void, onAudioSubmit: (audioBlob: Blob) => Promise<any> }) => {
const { t } = useTranslation();

const [accessError, setAccessError] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const barHeightsRef = useRef<number[]>(Array(3).fill(0));
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const animationRef = useRef<number | null>(null);

  const drawOscilloscopeBars = useCallback(() => {
    if (!analyserRef.current || !dataArrayRef.current) return;

    analyserRef.current.getByteFrequencyData(dataArrayRef.current);
    const dataArray = dataArrayRef.current;
    const middleValue = dataArray[1] * 0.6;
    const firstValue = dataArray[0] * 0.4;
    const barHeight1 = Math.min(Math.max(middleValue, 20), 36);
    const barHeight2 = Math.min(Math.max(firstValue, 20), 36);

    barHeightsRef.current = [barHeight2, barHeight1, barHeight2];

    // Update DOM directly
    const bars = document.querySelectorAll('.oscilloscope-bar');
    bars.forEach((bar, index) => {
      (bar as HTMLElement).style.height = `${barHeightsRef.current[index]}px`;
    });

    animationRef.current = setTimeout(drawOscilloscopeBars, 1000 / 30) as unknown as number; // 10 FPS
  }, []);

  useEffect(() => {
    if (isRecording) {
      drawOscilloscopeBars();
    } else if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
  }, [isRecording, drawOscilloscopeBars]);

  const submitAudio = async (audioBlob: Blob) => {
    setIsLoading(true);
    await onAudioSubmit(audioBlob);
    setIsLoading(false);
  }

  const startRecording = async () => {
    try {
      setStartRecording();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new AudioContext();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      analyserRef.current = audioContextRef.current.createAnalyser();
      source.connect(analyserRef.current);
      analyserRef.current.fftSize = 2048;
      const bufferLength = analyserRef.current.frequencyBinCount;
      dataArrayRef.current = new Uint8Array(bufferLength);

      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.start();
      setIsRecording(true);
      setRecordingTime(0);
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
      setAccessError(false);
    } catch (err) {
      console.error('Error accessing microphone', err);
      setAccessError(true);
    }
  };

  const stopRecording = async (submit: boolean) => {
    console.log('stopRecording');
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
  
      mediaRecorderRef.current.onstop = async () => {
        if (submit) {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          await submitAudio(audioBlob);
          audioChunksRef.current = []; // Clear after Blob is created and submitted
        }
        setIsRecording(false);
        setEndRecording();
      };
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  if (isRecording) {
    return (
        <AnimatePresence>
      <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className='flex items-center justify-between bg-white gap-3 w-full'>
        <div
          className='w-[40px] h-[40px] bg-white rounded-full flex items-center justify-center cursor-pointer border-1 border-gray-300 hover:opacity-50 transition-opacity duration-150'
          onClick={() => {
            stopRecording(false);
          }}
        >
          <IconTrash className='fill-current text-gray-800 w-6 h-6' />
        </div>
        <div className={clsx('flex items-center justify-center gap-4', isLoading && 'opacity-0')}>
          <div className="h-[40px] flex items-center gap-1">
            {[0, 1, 2].map((index) => (
              <div
                key={index}
                className="oscilloscope-bar w-[20px] bg-blue-200 rounded-full transition-all duration-75 ease-in-out"
                style={{ height: 0 }}
              />
            ))}
          </div>
          <span className='block text-sm text-blue-600 font-medium w-[32px]'>{formatTime(recordingTime)}</span>
        </div>
        <div
          className={clsx('w-[40px] h-[40px] bg-blue-600 rounded-full flex items-center justify-center cursor-pointer shadow-xxs hover:opacity-50 transition-opacity duration-150', isLoading && 'opacity-25')}
          onClick={() => {
            stopRecording(true);
          }}
        >
          {isLoading ? <Loader color='white' /> : <IconForward className='fill-current text-white w-6 h-6' />}
        </div>
      </motion.div>
      </AnimatePresence>
    )
  }

  return (
    <AnimatePresence>
    <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} whileHover={{ opacity: 0.5 }} className={className && className}>
        <Tooltip content={<span className='block text-xs font-medium w-[144px]'>Please, allow access to microphone to record your answer.</span>} showOnlyIf={accessError} position='top'>
      <button onClick={() => {startRecording()}}>
          <div className="flex items-center justify-center w-[40px] h-[40px] rounded-lg relative overflow-hidden border-gray-300 border-1">
            {/* <div className='absolute bg-blue-600 rounded-full -top-[32px] left-[50%] w-full h-full blur-[12px] opacity-[50%]'></div>
            <div className='absolute bg-[#19BEF2] rounded-full -top-8 -left-[50%] w-full h-full blur-[12px] opacity-[50%]'></div> */}
            <IconMic className='fill-current text-gray-800' />
            {accessError && <div className="absolute -top-1 -right-1 w-[16px] h-[16px] bg-orange-600 rounded-full flex items-center justify-center">
                <b className='text-white text-xs'>!</b>
                </div>}
          </div>
      </button>
      </Tooltip>
    </motion.div>
    </AnimatePresence>
  );
};

export default AudioRecorder;