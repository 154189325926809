import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { IconMore } from '../../../../icons';
import MessageTagContextMenu from './MessageTagContextMenu';

export interface MessageTagProps {
	className?: string,
	colorClassName?: string;
	withContextMenu?: boolean;
	onClick?: () => void;
	onDeleteClick?: () => void;
}
export function MessageTag(props: PropsWithChildren<MessageTagProps>) {

	return <div className={clsx(
		"message-tag w-fit-content py-[2px] px-2 rounded-full",
		"group/tag",
		"flex flex-row gap-1 items-center",
		{ "bg-blue-200 hover:bg-blue-300": !props.colorClassName },
		props.className,
		props.colorClassName
	)}
		onClick={props.onClick}
	>
		<div className='message-tag__placeholder'>{props.children}</div>

		{/* {!!props.canDelete && <div className={clsx("message-tag__del-placeholder",
			"w-0 group-hover/tag:w-[16px] transition-all duration-200 ease-in-out overflow-hidden",
			"cursor-pointer text-gray-800 hover:text-red-600"
		)}>
			<IconDelete width={16} height={16} className='size-4 fill-current' onClick={(e) => {
				if (props.onDeleteClick) {
					e.stopPropagation();
					props.onDeleteClick();
				}
			}} />
		</div>} */}

		{!!props.withContextMenu && <div className={clsx("message-tag__contextmenu-placeholder",
			"w-0 group-hover/tag:w-[16px] transition-all duration-200 ease-in-out overflow-hidden",
			"cursor-pointer "
		)}>
			<MessageTagContextMenu onDeleteClick={props.onDeleteClick}>
				<IconMore width={16} height={16} className="fill-current" />
			</MessageTagContextMenu>
		</div>}
	</div>;
}
