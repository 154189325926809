import React from 'react';
import { TaggableChatMessage } from '../Chat/TaggableChatMessage';
import { ReportChatMessage } from '../Models/ReportChatMessage';
import clsx from 'clsx';


export function AIChatMessages(props: { messages: ReportChatMessage[]; className?: string; showDate?: boolean; showScrollPadding: boolean; }) {

	return <div className={clsx("ai-chat-messages", props.className)}>
		<div className='ai-chat-messages__content flex flex-col gap-2'>
			{props.messages.map((message, i) => <TaggableChatMessage
				key={message.id}
				message={message}
				showDate={props.showDate}
			/>)}
		</div>
	</div>;
}