import React, { useState, useRef } from "react";
import parse from "html-react-parser";
import _ from "lodash";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import TestBranding from "./TestBranding";

import { PreferenceBlock, Block, FirstClickBlock } from "../../../../../models/Test";
import { IconImage, IconCheck, IconCancel, IconQuestionMark, IconInfo, IconShow } from "../../../../../icons";
import Button from "../../../../Button";
import FirstClickPointer from "../../../../Test/FirstClickPointer";

import { getFirstClickCoordinates } from "../../../../../utils";

import useIsMobile from "../hooks/useIsMobile";

import { locale, interfaceText } from "../../../../../helpers";
import TaskSidebar from "../TaskSidebar";

import { motion } from "framer-motion";
import Modal from "../../../../Modal";

import useDimensions from "../../../../Common/Hooks/useDimensions";

import ShowTaskButton from "./ShowTask";

type TaskProps = {
  blockData: FirstClickBlock & Block;
};

function Task(props: TaskProps) {
  return (
    <div>
      <div className="text-lg font-medium flex-shrink-0 break-words flex flex-col w-full">
        <div>{parse(props.blockData.questionHtml || props.blockData.text)}</div>
      </div>
    </div>
  );
}

type FirstclickProps = {
  blockData: FirstClickBlock & Block;
  blockAnswer: any;
  handleBlockAnswer: any;
  nextStepOnClick: any;
  withProgress?: boolean;
  totalSteps?: number;
  currentStep?: number;
  responseStart?: any;
  setResponseStart?: any;
  isAnswerValid: boolean;
};

function Firstclick(props: FirstclickProps) {
  const [startTime, setStartTime] = useState<null | number>( null);
  const isMobile = useIsMobile();

  const [imageReady, setImageReady] = useState(false);

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(true);

  const taskStarted = !!props.responseStart;
  const isValid = props.blockAnswer?.selectedOptions && props.blockAnswer?.selectedOptions.length > 0;

  const taskContainerRef = useRef<HTMLDivElement | null>(null);
  const imageContainerRef = useRef<HTMLImageElement | null>(null);

  const position = props.blockAnswer.clickData;

  const { height: taskContainerHeight } = useDimensions({ ref: taskContainerRef });

  return (
    <>
    <div className="firstclick__wrapperflex relative flex-1 flex-col h-full w-full overflow-y-hidden">
    {taskStarted && (
        <ShowTaskButton
          openTaskModal={() => {
            if (!position) {
              setIsTaskModalOpen(true);
            }
          }}
          buttonName={(interfaceText as any).test[locale()].showTask}
        />
      )}
      <div
        className={clsx(
          "flex flex-1 flex-col h-full overflow-y-auto w-full relative"
        )}
        ref={taskContainerRef}
      >
        <div className={`flex justify-center`}>
          <div className={clsx("relative inline-block")} ref={imageContainerRef}>
            {position && (
              <FirstClickPointer
                imageContainerRef={imageContainerRef}
                withPopover={true}
                position={position}
                opacity={100}
                popoverContentBlock={
                  <div className="flex flex-col items-center justify-center">
                    <Button
                      name={""}
                      handler={props.nextStepOnClick}
                      icon={<IconCheck width={24} height={24} className="fill-current text-white" />}
                      small
                      disabled={!props.isAnswerValid}
                      className="mb-2"
                    />
                    <Button
                      small
                      type="ghost"
                      name={""}
                      icon={
                        <IconCancel
                          width={20}
                          height={20}
                          className="fill-current text-gray-600 hover:text-gray-800 transition-all ease-in duration-75"
                        />
                      }
                      handler={() => {
                        props.handleBlockAnswer("clickData", null);
                      }}
                    />
                  </div>
                }
              />
            )}
            <img
              id="firstClickImage"
              onClick={(e) => {
                if (!position && startTime) {
                  props.handleBlockAnswer("clickData", {
                    left: getFirstClickCoordinates(e).left,
                    top: getFirstClickCoordinates(e).top,
                    responseTime: _.now() - startTime,
                  });
                }
              }}
              onLoad={() => {
                setImageReady(true);
              }}
              src={props.blockData.image}
              alt=""
              className={clsx(
                "lg:mt-0 max-w-full transition-all duration-150 ease-in",
                position && "filter brightness-60"
              )}
              style={props.blockData.scaling === "scale-down" ? { maxHeight: taskContainerHeight } : undefined}
            />
          </div>
        </div>
        {/* <div className="sticky bottom-2 left-4 z-9999">
            <Button
              name={"Show image"}
              handler={props.nextStepOnClick}
              icon={<IconShow width={24} height={24} className="fill-current text-white mr-2" />}
            />
          </div> */}

        {/* <TaskSidebar
        sidebarState={sidebarState}
        setSidebarState={setSidebarState}
        parentRef={taskContainerRef}
        sidebarContent={
          <div className="flex flex-col h-full justify-between">
            <Task blockData={props.blockData} />
              {(!props.responseStart || isMobile) && (
                <div className="border-t-1 border-gray-300 p-4 flex justify-end">
                <Button
                  name={(interfaceText as any).firstclickTesting[locale()].showImage}
                  handler={() => {
                    props.setResponseStart(_.now());
                    setStartTime(_.now());
                    if (isMobile) {
                      setSidebarState("hidden");
                    } else {
                      setSidebarState("default");
                    }
                  }}
                  large
                  icon={<IconImage width={20} height={20} className="fill-current text-white mr-2" />}
                  disabled={!imageReady}
                />
                </div>
              )}
          
          </div>
        }
        content={
          <div className={clsx("w-full h-full overflow-y-auto bg-gray-100")}>
              {taskStarted && isMobile && (
              <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.5, duration: 0.2 }} className="px-2 py-[3px] absolute z-[99] rounded-full shadow-[0px_2px_4px_3px_rgba(0,0,0,0.1)] left-2 top-2 bg-white opacity-[90%] backdrop-blur-lg flex items-center gap-1 cursor-pointer hover:opacity-50"
              onClick={() => {
                if (!position) {
                  setSidebarState("default");
                }
              }}>
                <IconQuestionMark width={20} height={20} className="fill-current text-black" />
                <motion.span className="text-gray-800 text-xs font-medium">{(interfaceText as any).test[locale()].showTask}</motion.span>
              </motion.div>
            )}
          <div className={`flex justify-center p-2 md:p-4`}>
            <div className="relative inline-block" ref={imageContainerRef}>
              {position && (
                <FirstClickPointer
                imageContainerRef={imageContainerRef}
                withPopover={true}
                  position={position}
                  opacity={100}
                  popoverContentBlock={
                    <div className="flex flex-col items-center justify-center">
                      <Button
                        name={""}
                        handler={props.nextStepOnClick}
                        icon={<IconCheck width={24} height={24} className="fill-current text-white" />}
                        small
                        disabled={!props.isAnswerValid}
                        className="mb-2"
                      />
                      <Button
                        small
                        type="ghost"
                        name={""}
                        icon={
                          <IconCancel
                            width={24}
                            height={24}
                            className="fill-current text-gray-600 hover:text-gray-800 transition-all ease-in duration-75"
                          />
                        }
                        handler={() => {
                          props.handleBlockAnswer("clickData", null);
                        }}
                      />
                    </div>
                  }
                />
              )}
              <img
                id="firstClickImage"
                onClick={(e) => {
                  if (!position && startTime) {
                    props.handleBlockAnswer("clickData", {
                      left: getFirstClickCoordinates(e).left,
                      top: getFirstClickCoordinates(e).top,
                      responseTime: _.now() - startTime,
                    });
                  }
                }}
                onLoad={() => {
                  setImageReady(true);
                }}
                src={props.blockData.image}
                alt=""
                className={clsx("lg:mt-0 max-w-full transition-all duration-150 ease-in", props.blockData.scaling === "scale-down" && "max-h-[calc(100dvh_-_1rem)]")}
                style={position ? { filter: "brightness(60%)" } : undefined}
              />
            </div>
          </div>
          </div>
        }
      /> */}
      </div>
      </div>
      <Modal
        isOpen={isTaskModalOpen}
        setIsOpen={setIsTaskModalOpen}
        width="440px"
        disallowClickOutside
        sidebarMode
        backdrop
      >
        <div className="flex flex-col gap-4 p-6 h-full justify-between">
          <div className="flex flex-col gap-4">
            <TestBranding />
            <Task blockData={props.blockData} />
            <p>{(interfaceText as any).test[locale()].firstClickInstruction}</p>
          </div>
          <div className="flex justify-end">
            <Button
              name={(interfaceText as any).firstclickTesting[locale()].showImage}
              handler={() => {
                setStartTime(_.now());
                props.setResponseStart(_.now());
                setIsTaskModalOpen(false);
              }}
              large
              icon={<IconImage width={20} height={20} className="fill-current text-white mr-2" />}
              disabled={!imageReady}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Firstclick;
