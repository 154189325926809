import React, { useState, useEffect, useRef, useCallback } from "react";
import "../../tailwind.generated.css";
import clsx from "clsx";

import { blockTypeMap } from "../Common";
import { Block, BlockType } from "../../models/Test";

import { getBlockName } from "../TestEditor/utils";

import { Trans } from "react-i18next";
import { getResponseDate, getFormattedDate } from "./utils";

import { IconHire } from "../../icons";

type ReportContentListProps = {
  answers: any[];
  selectAnswer: (answerId: string) => void;
  selectedAnswerId: string | null;
};

const ResponsesList = ({ answers, selectAnswer, selectedAnswerId }: ReportContentListProps) => {
  const responsesListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        const currentIndex = answers.findIndex(answer => answer.answerId === selectedAnswerId);
        if (currentIndex !== -1) {
          const nextIndex = event.key === "ArrowDown" ? currentIndex + 1 : currentIndex - 1;
          if (nextIndex >= 0 && nextIndex < answers.length) {
            selectAnswer(answers[nextIndex].answerId);
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [answers, selectedAnswerId, selectAnswer]);


  const handleSelectAnswer = (answerId: string) => {
    const element = document.getElementById(answerId);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
    selectAnswer(answerId);
  };

  const [showBorders, setShowBorders] = useState<{top: boolean, bottom: boolean}>({top: false, bottom: false});
  const [responsesListMaxHeight, setResponsesListMaxHeight] = useState<number>(0);

  useEffect(() => {

    const scrollContainer = document.getElementById('test-page__wrapper');

    const handleScroll = () => {
      if (!responsesListRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = responsesListRef.current;
      const isScrolled = scrollTop > 0;
      const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight;

      const responsesListMaxHeight = window.innerHeight - responsesListRef.current.getBoundingClientRect().top;
      setResponsesListMaxHeight(responsesListMaxHeight);

      setShowBorders({
        top: isScrolled,
        bottom: isScrolled && !isScrolledToBottom,
      });
    };
  
    const listElement = responsesListRef.current;
    listElement?.addEventListener('scroll', handleScroll);

    if (scrollContainer) {
        scrollContainer.addEventListener('scroll', handleScroll);
        scrollContainer.addEventListener('resize', handleScroll);
    }
  
    // Initial check in case the list is already scrolled when the component mounts
    handleScroll();
  
    return () => {
      listElement?.removeEventListener('scroll', handleScroll);
      if (scrollContainer) {
          scrollContainer.removeEventListener('scroll', handleScroll);
          scrollContainer.removeEventListener('resize', handleScroll);
      }
    };
  }, [answers]); // Re-run when answers change

  if (!answers.length) {
    return null;
  }

  return (
    <div ref={responsesListRef} style={{height: responsesListMaxHeight}} className={clsx("responses-list__wrapper flex flex-col gap-2 overflow-y-auto pb-4 hideScrollbar overscroll-contain font-medium text-sm", showBorders.top && "border-t-1 border-t-gray-300", showBorders.bottom && "border-b-1 border-b-gray-300")} tabIndex={0}>
      {answers.map((answer, i) => {
        const isSelected = selectedAnswerId === answer.answerId;

        return (
          <div id={answer.answerId} key={answer.answerId} className={clsx("flex gap-2 items-center justify-start px-2 py-2 rounded-md cursor-pointer transition-all duration-75 ease-in", isSelected && "bg-white shadow-xs text-gray-800", !isSelected && "hover:bg-gray-300 text-gray-700")} onClick={() => handleSelectAnswer(answer.answerId)}>
            <IconHire className={clsx("w-5 h-5 shrink-0 fill-current", isSelected && "text-gray-800", !isSelected && "text-gray-700")} />
            <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px]">
              {getFormattedDate(getResponseDate(answer))}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ResponsesList;