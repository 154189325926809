import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";

type ButtonProps = {
	name?: string | JSX.Element;
	type?: "primary" | "secondary" | "tertiary" | "danger" | "ghost" | "dashed";
	handler?: (event?: React.MouseEvent<HTMLElement>) => void;
	large?: boolean;
	disabled?: boolean;
	className?: string;
	isLoading?: boolean;
	small?: boolean;
	extrasmall?: boolean;
	fullWidth?: boolean;
	extralarge?: boolean;
	icon?: any;
	id?: string;
	title?: string;
	testId?: string;
};

const typeClassMap = {
	primary: "bg-blue-600 text-white hover:bg-blue-700",
	secondary: "border-1 border-gray-400 hover:border-gray-500",
	tertiary: "text-gray-800 bg-white",
	danger: "bg-red-600 text-white hover:bg-red-700",
	dashed: "text-gray-700 border-dashed border-2 border-gray-400 hover:text-gray-500",
	ghost: "hover:opacity-50",
};

const sizeClassMap = {
	extrasmall: "text-xs",
	small: "text-base",
	large: "text-xl h-10",
	extralarge: "text-2xl",
};

const paddingClassMap = {
	extrasmall: {
		icon: "py-1 px-2",
		noIcon: "px-2 py-1",
	},
	small: {
		icon: "py-1 px-2",
		noIcon: "px-2 py-2",
	},
	large: {
		icon: "py-1 px-3",
		noIcon: "px-3 py-3",
	},
	extralarge: {
		icon: "py-1 px-4",
		noIcon: "px-4 py-4",
	},
	default: {
		icon: "py-1 px-2",
		noIcon: "px-3 py-2",
	},
};

const Button: FunctionComponent<ButtonProps> = ({
	name = "",
	type = "primary",
	handler,
	large,
	disabled,
	className,
	isLoading,
	small,
	extrasmall,
	fullWidth,
	extralarge,
	icon,
	id,
	title,
	testId,
}) => {
	const { t } = useTranslation();
	const loaderColorMap: {
		[key: string]: "white" | "default" | "blue" | "black";
	} = {
		primary: "white",
		secondary: "default",
		tertiary: "default",
		danger: "white",
		ghost: "default",
		dashed: "default",
	};

	const size = extrasmall ? "extrasmall" : small ? "small" : large ? "large" : extralarge ? "extralarge" : null;
	const sizeClass = size ? sizeClassMap[size] : "text-base h-8";
	const typeClass = typeClassMap[type];

	let paddingClass = paddingClassMap.default.noIcon;
	if (icon) {
		paddingClass = size && paddingClassMap[size]?.icon ? paddingClassMap[size].icon : paddingClassMap.default.icon;
	} else {
		paddingClass = size && paddingClassMap[size]?.noIcon ? paddingClassMap[size].noIcon : paddingClassMap.default.noIcon;
	}

	return (
		<div
			id={id}
			data-testid={testId}
			style={{
				width: fullWidth ? "100%" : "fit-content",
			}}
			title={title}
className={clsx(
	className,
	"object-center rounded-full font-medium leading-5 flex p-2 items-center group transition-all duration-75 ease-in",
	sizeClass,
	typeClass,
	paddingClass,
	(disabled || isLoading) ? "opacity-50 cursor-not-allowed disabled" : "cursor-pointer",
	fullWidth && "text-center"
)}
			onClick={(e) => {
				if (handler && !disabled) {
					e.stopPropagation();
					handler(e);
				}
			}}
		>
			{isLoading && (
				<div className="flex items-center justify-center whitespace-nowrap">
					<Loader color={loaderColorMap[type]} className="mr-2" />
					<span className="block shrink-0">{t("Please, wait...")}</span>
				</div>
			)}
			{!isLoading && (
				<div className={clsx("flex items-center justify-center w-full whitespace-nowrap")}>
						{icon} {name}
				</div>
			)}
		</div>
	);
};

export default Button;