import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ConditionType, Logic, LogicCondition, LogicStatement, Operator, VisibilityLogicType } from '../../../models/Logic';
import { IBlockTestResponse, isOpenQuestionsResponse, isScaleResponse, isChoiceResponse, isPreferenceResponse, isFigmaResponse } from '../../../models/Response';
import { Block, BlockType } from '../../../models/Test';
import { getBlockLogic } from '../../../utils/tests';
import { PWStore } from './useSessionStorage';

type AnswersMap = Record<string, IBlockTestResponse | null>;

export const FINAL_BLOCK_ID = "FINAL";

const ANSWER_BY_BLOCK_KEY = 'answers_by_block';
const CURRENT_STEP_KEY = 'current_step';
const TEST_CURRENT_VERSION_HASH = 'current_version_hash';

export function useLogic(content: Block[], answerByBlockStore?: PWStore) {
  const currentStepInitialValue = answerByBlockStore?.getItem<number>(CURRENT_STEP_KEY) || 1;
  const [currentStep, setCurrentStep] = useState(currentStepInitialValue);
  const [answersByBlock, setAnswersByBlock] = useState<AnswersMap>({});

  const totalSteps = _.get(content, "length", -1) + 1;
  const isLastBlock = currentStep === totalSteps;

  // this effect is called when answer is pushed to answersByBlock
  useEffect(() => {
    if (!content) return;
    if (currentStep > content.length) {
      console.log('End of test')
      return;
    }

    const currentIndex = currentStep - 1;
    const currentBlock = content[currentIndex];
    const currentBlockAnswer = answersByBlock[currentBlock.blockId];

    // this condition helps to exit if [content] changed for the first time and we need to show first question
    // there are no answers yet
    if (currentStep === currentStepInitialValue && typeof currentBlockAnswer === 'undefined') return;

    const nextStepIndex = getNextStepIndex(currentIndex, currentBlock, answersByBlock)
    const nextStep = nextStepIndex + 1;

    setCurrentStep(nextStep);

    answerByBlockStore?.setItem(ANSWER_BY_BLOCK_KEY, answersByBlock);
    answerByBlockStore?.setItem(CURRENT_STEP_KEY, nextStep);
    answerByBlockStore?.setItem(TEST_CURRENT_VERSION_HASH, getContentHash(content));
  }, [answersByBlock]);

  useEffect(() => {
    if (!content || !answerByBlockStore) return;
    const preservedAnswersByBlock = answerByBlockStore.getItem<AnswersMap>(ANSWER_BY_BLOCK_KEY);
    const preservedHash = answerByBlockStore?.getItem<string>(TEST_CURRENT_VERSION_HASH);
    const testContentHasChanged = preservedHash !== getContentHash(content);

    if (testContentHasChanged) {
      setCurrentStep(1);
      console.log('Test content has changed')
      return;
    }
    if (preservedAnswersByBlock) setAnswersByBlock(preservedAnswersByBlock);

    // const preservedCurrentStep = answerByBlockStore.getItem<number>(CURRENT_STEP_KEY);
    // if (preservedCurrentStep && typeof preservedCurrentStep === 'number') setCurrentStep(preservedCurrentStep);
  }, [content])

  function getNextStepIndex(currentIndex: number, currentBlock: Block, answersByBlock: AnswersMap) {
    const logic = getBlockLogic(currentBlock);

    let nextIndex = logic ?
      getNextBlockIndex(currentBlock, logic, content, answersByBlock) :
      currentIndex + 1;

    return checkVisibilityLogic(nextIndex, content, answersByBlock);
  }

  function getCurrentBlockId() {
    const currentIndex = currentStep - 1
    const currentBlock = content[currentIndex]

    return currentBlock ? currentBlock.blockId : FINAL_BLOCK_ID;
  }

  function getNextBlockId(currentBlockAnswer: IBlockTestResponse | undefined | null) {
    const currentIndex = currentStep - 1
    const currentBlock = content[currentIndex]
    const newAnswersByBlock = { ...answersByBlock, [currentBlock.blockId]: currentBlockAnswer ?? null }

    const nextStepIndex = getNextStepIndex(currentIndex, currentBlock, newAnswersByBlock)
    const nextBlock = content[nextStepIndex]

    return nextBlock ? nextBlock.blockId : FINAL_BLOCK_ID;
  }

  function goFinalStep() {
    setCurrentStep(totalSteps)
  }

  function goNextStep(currentBlockAnswer: IBlockTestResponse | undefined) {
    const currentBlock = content[currentStep - 1];

    setAnswersByBlock((answerByBlock) => {
      const update = { ...answerByBlock };
      if (!(currentBlock.blockId in update)) {
        // записываем новый ответ, только если он еще не был записан. 
        // Возможна ситуация, когда респ ответил, и в момент сохранения обновил страницу - в этом случае у нас может успеть записаться первый ответ,
        // но у респа откроется тот же вопрос с возможностью ответить иначе
        update[currentBlock.blockId] = currentBlockAnswer ?? null;
      }
      return update;
    });
  }

  function getContentHash(content: Block[]) {
    return content.map(b => b.blockId).join(',');
  }

  return {
    goNextStep,
    getCurrentBlockId,
    getNextBlockId,
    goFinalStep,
    currentStep,
    isLastBlock,
    totalSteps
  }
}

function getNextBlockIndex(currentBlock: Block, logic: Logic, testBlocks: Block[], answerByBlock: AnswersMap) {
  const resolvedStatement = logic.statements.find(st => resolveConditionGroup(st, currentBlock, answerByBlock, testBlocks));

  const nextBlockId = resolvedStatement?.jumpTo || logic.elseJumpTo;
  let nextBlockIndex = getIndexByBlockId(nextBlockId, testBlocks, currentBlock);

  return nextBlockIndex;
}

/**
 * Runs visibility logic for block and if it's not visible - looks for the first next visible block
 * @param nextBlockIndex Index of the block to run its visibility logic
 * @param testBlocks 
 * @param answerByBlock 
 * @returns 
 */
function checkVisibilityLogic(nextBlockIndex: number, testBlocks: Block[], answerByBlock: AnswersMap) {
  let isNextBlockVisible = false;

  while (!isNextBlockVisible && nextBlockIndex < testBlocks.length) {
    let nextBlock = testBlocks[nextBlockIndex];
    let nextBlockLogic = getBlockLogic(nextBlock);

    if (nextBlockLogic?.visibility) {
      const statement = nextBlockLogic.visibility.find(st => resolveConditionGroup(st, nextBlock, answerByBlock, testBlocks));
      isNextBlockVisible = !!statement;

      if (!isNextBlockVisible) nextBlockIndex++;
      else break;
    }
    else {
      isNextBlockVisible = true;
    }
  }
  return nextBlockIndex;
}

function getIndexByBlockId(nextBlockId: string, testBlocks: Block[], currentBlock: Block) {
  let nextBlockIndex = -1;

  if (nextBlockId === "thankyouscreen") {
    nextBlockIndex = testBlocks.length;
  }
  else if (nextBlockId === "nextquestion") {
    nextBlockIndex = testBlocks.findIndex(b => b.blockId === currentBlock.blockId) + 1;
  }
  else {
    nextBlockIndex = testBlocks.findIndex(block => block.blockId === nextBlockId);
  }

  return nextBlockIndex;
}

function resolveConditionGroup(statement: LogicStatement, currentBlock: Block, answerByBlock: AnswersMap, testBlocks: Block[]) {

  const subconditionsResult = statement.subconditions.reduce((acc, subcondition) => {
    let conditionBlockAnswer = answerByBlock[currentBlock.blockId];
    let conditionSourceBlock = currentBlock as Block | undefined;

    if (subcondition.valueSource) {
      conditionBlockAnswer = answerByBlock[subcondition.valueSource] ?? null;
      conditionSourceBlock = testBlocks.find(b => b.blockId === subcondition.valueSource);
    }

    const isConditionFulfilled = conditionSourceBlock ? resolveSubcondition(conditionSourceBlock.type, conditionBlockAnswer, subcondition) : false;

    if (subcondition.operator === Operator.and) {
      return acc === undefined ? isConditionFulfilled : acc && isConditionFulfilled;
    } else {
      return acc === undefined ? isConditionFulfilled : acc || isConditionFulfilled;
    }
  }, undefined as (boolean | undefined));

  if (statement.visibilityType === VisibilityLogicType.show) {
    return Boolean(subconditionsResult);
  }
  if (statement.visibilityType === VisibilityLogicType.hide) {
    return Boolean(!subconditionsResult);
  }

  return Boolean(subconditionsResult);
}

function resolveSubcondition(blockType: BlockType, blockAnswer: IBlockTestResponse | null, condition: LogicCondition) {
  switch (condition.condition) {

    case ConditionType.is:
      if (!blockAnswer) return false;
      switch (blockType) {
        case BlockType.scale:
          return isScaleResponse(blockAnswer) &&
            blockAnswer.selectedOption === condition.value;
      }

    case ConditionType.isGreaterThan:
      if (!blockAnswer) return false;
      switch (blockType) {
        case BlockType.scale:
          return isScaleResponse(blockAnswer) &&
            parseInt(blockAnswer.selectedOption) > parseInt(condition.value);
      }

    case ConditionType.isLessThan:
      if (!blockAnswer) return false;
      switch (blockType) {
        case BlockType.scale:
          return isScaleResponse(blockAnswer) &&
            parseInt(blockAnswer.selectedOption) < parseInt(condition.value);
      }

    case ConditionType.contains:
      if (!blockAnswer) return false;
      switch (blockType) {
        case BlockType.openquestion:
          return isOpenQuestionsResponse(blockAnswer) &&
            blockAnswer.textValue?.toUpperCase().includes(condition.value.toUpperCase());
        case BlockType.choice:
          return isChoiceResponse(blockAnswer) &&
            blockAnswer.selectedOptions.find(o => o.id === condition.value) ? true : false;
        case BlockType.preference:
          return isPreferenceResponse(blockAnswer) &&
            blockAnswer.selectedOptions.find(o => o.id === condition.value) ? true : false;
      }

    case ConditionType.doesNotContain:
      if (!blockAnswer) return false;
      switch (blockType) {
        case BlockType.openquestion:
          return isOpenQuestionsResponse(blockAnswer) &&
            !blockAnswer.textValue?.toUpperCase().includes(condition.value.toUpperCase());
        case BlockType.choice:
          return isChoiceResponse(blockAnswer) &&
            blockAnswer.selectedOptions.find(o => o.id === condition.value) ? false : true;
        case BlockType.preference:
          return isPreferenceResponse(blockAnswer) &&
            blockAnswer.selectedOptions.find(o => o.id === condition.value) ? false : true;
      }

    case ConditionType.gaveUp:
      if (!blockAnswer) return false;
      switch (blockType) {
        case BlockType.figma:
          return isFigmaResponse(blockAnswer) && blockAnswer.givenUp === true;
      }

    case ConditionType.goalScreenIs:
      if (!blockAnswer) return false;
      switch (blockType) {
        case BlockType.figma:
          return isFigmaResponse(blockAnswer) && blockAnswer.path[blockAnswer.path.length - 1] === condition.value;
      }

    case ConditionType.isEmpty:
      switch (blockType) {
        case BlockType.openquestion:
          return !blockAnswer || isOpenQuestionsResponse(blockAnswer) && !blockAnswer.textValue;
        case BlockType.preference:
        case BlockType.choice:
        case BlockType.scale:
          return !blockAnswer;
      }

    default:
      return false;
  }
}