import React, { useContext } from 'react';
import { IconTag } from '../../../../icons';
import { ChatMessageBaseProps, UserMessage, AssistantMessage } from './ChatMessage';
import { TagSelect } from './TagSelect';
import { useTags } from '../useTags';
import { IMessageTag } from '../../../../Common/schema/Chats/UpdateChatMessageDTO';
import Popover from '../../../Popover2';
import { TestBlockReportContext } from '../../ReportContext';
import clsx from 'clsx';

export function TaggableChatMessage(props: ChatMessageBaseProps & { showDate?: boolean }) {
	const reportContext = useContext(TestBlockReportContext);
	const tags = useTags({ testId: reportContext?.testId || '', blockId: reportContext?.block.blockId || '' });

	const canAddOrDeleteTags = !reportContext?.sharedByLink;

	function onTagSelect(tag: { id?: number, name: string, color: string }) {
		tags.tagMessage.mutate({ messageId: props.message.id, tagId: tag.id, name: tag.name, color: tag.color });
	}

	function untagMessage(tag: IMessageTag) {
		tags.untagMessage.mutate({ tagId: tag.id, request: { messageId: props.message.id } });
	}

	if (!props.message.isUser) return <AssistantMessage {...props} />;

	return <>
		<div className={clsx("taggable-message flex flex-row items-center group/taggablemsg w-full gap-1", props.className)}>
			<div className='w-full'>
				<UserMessage
					className='w-full'
					message={props.message}
					onTagDelete={untagMessage}
					canDeleteTags={canAddOrDeleteTags}
					tempTag={tags.tagMessage.isPending ? tags.tagMessage.variables : undefined}
				/>
				{!!props.showDate && <div className='taggable-message__date mt-2 text-xs text-gray-700'>
					{props.message.createdAt?.toLocaleDateString()} {props.message.createdAt?.toLocaleTimeString()}
				</div>}
			</div>
			{canAddOrDeleteTags && <div className='taggable-message__tag-button opacity-0 group-hover/taggablemsg:opacity-100 transition-opacity duration-200 ease-in-out'>
				<Popover
				position='bottom-end'
					content={
						<div className="context-menu-items-list w-56 text-base rounded-md">
							<TagSelect excludeTags={props.message.tags} onTagSelect={onTagSelect} />
						</div>
					}
					anchorClassName="context-menu__anchor hover:opacity-75 cursor-pointer transition-opacity duration-75 ease-in ml-2"
				>
					<button className="tag-button size-8 rounded-full flex items-center justify-center border-solid border-1 border-gray-200 shadow-xs cursor-pointer">
						<IconTag width={20} height={20} className='size-5 fill-current' />
					</button>
				</Popover>
			</div>}
		</div >
	</>;
}
