import React, { PropsWithChildren } from 'react';
import Popover from '../../../Popover2';
import { MenuItem } from './MenuItem';
import clsx from 'clsx';

import { IconMore } from '../../../../icons';

interface ContextMenuArgs extends PropsWithChildren {
	menuItems: IContextMenuItem[],
	position?: string,
	styleMode?: string,
	gap?: number,
	className?: string;
	onClick: (item: IContextMenuItem) => void | Promise<void>,
	onOpen?: (() => void) | null,
	onClose?: (() => void) | null,
	isLoading?: boolean;
}

function MenuIcon() {
	return (
		<IconMore width={24} height={24} className="fill-current ml-3 group-hover/test-list-item:opacity-100 opacity-0 text-gray-700 hover:text-gray-800 transition-all duration-75 ease-in" />
	);
}

export function ContextMenu({ menuItems, className, position = 'bottom-right', styleMode = 'light', gap = 2, onClick, onOpen, onClose, isLoading, ...props }: ContextMenuArgs) {

	const gapClass = `gap-${gap}`;

	const onMenuItemClick = (e: React.MouseEvent, item: IContextMenuItem, popoverSetIsVisible: (arg: boolean) => void) => {
		e.stopPropagation();
		if (!item.disabled) {
			onClick(item);
			popoverSetIsVisible(false);
			if (onClose) {
				onClose();
			}
		}
	}

	return (
		<Popover
			position="bottom-end"
			content={popoverSetIsVisible => (
				<div className="context-menu-items-list w-auto text-base rounded-md" onClick={(e) => e.stopPropagation()}>
					<div className={`flex flex-col items-start`}>
						{menuItems.map((item, i) => {
							if (item.code === "divider") {
								return <div key={item.code + i} className={`h-px my-2 w-full bg-gray-300`} />;
							}

							return (
								<MenuItem key={item.code} item={item} onClick={(e) => onMenuItemClick(e, item, popoverSetIsVisible)} />
							);
						})}
					</div>
				</div>
			)}
			anchorClassName="context-menu__anchor hover:opacity-75 cursor-pointer transition-opacity duration-75 ease-in"
		>
			{props.children || <MenuIcon />}
		</Popover>
	);
}


export interface IContextMenuItem {
	name: string;
	icon?: React.ReactElement;
	code: string;
	disabled?: boolean;
	description?: string;
}