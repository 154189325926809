import React, { useState, useRef } from "react";
import parse from "html-react-parser";
import _ from "lodash";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import { PreferenceBlock, Block } from "../../../../../models/Test";
import PreferenceSelect from "./PreferenceSelect";
import Button from "../../../../Button";

import useIsMobile from "../hooks/useIsMobile";

import { locale, interfaceText } from "../../../../../helpers";

import TestBranding from "./TestBranding";

type TaskProps = {
  blockData: PreferenceBlock & Block;
};

function Task(props: TaskProps) {
  return (
    <div>
      <div className="text-lg font-medium flex-shrink-0 break-words flex flex-col w-full">
        <div>{parse(props.blockData.questionHtml || props.blockData.text)}</div>
      </div>
    </div>
  );
}

type PreferenceProps = {
  blockData: PreferenceBlock & Block;
  blockAnswer: any;
  handleBlockAnswer: any;
  nextStepOnClick: any;
  withProgress?: boolean;
  totalSteps?: number;
  currentStep?: number;
  responseStart?: any;
  setResponseStart?: any;
};

function Preference(props: PreferenceProps) {
  const isMobile = useIsMobile();
  const isValid = props.blockAnswer?.selectedOptions && props.blockAnswer?.selectedOptions.length > 0;

  return (
    <>
    <div className={clsx("h-full flex flex-col gap-6 flex-1 overflow-y-auto overflow-x-hidden", isMobile ? "p-4" : "p-8" )}>
    <TestBranding />
      <Task blockData={props.blockData} />
  <PreferenceSelect
    data={props.blockData.replies}
    isRandomized={props.blockData.randomized}
    handler={props.handleBlockAnswer}
    responseStart={!!props.responseStart}
  />
</div>
<div className={clsx("w-full border-t-1 border-gray-300 bg-white flex justify-between p-4 gap-4")}>
  <div className={clsx("text-gray-700", isMobile ? "text-xs" : "text-sm")}>
    {(interfaceText as any).test[locale()].makeSureToViewAllImagesBeforeChoosingOne}
  </div>
      <Button
        type="primary"
        name={(interfaceText as any).test[locale()].allDone}
        handler={() => {
          props.nextStepOnClick();
        }}
        disabled={!isValid}
        className="whitespace-nowrap"
        large
      />
    </div>
</>
  );
}

export default Preference;
