import React from "react";
import "../tailwind.generated.css";

import Tooltip from "./Tooltip";
import { ReactComponent as IconQuestion } from "../icons/question.svg";
import clsx from "clsx";

const TooltipWithIcon = ({ text, size = 20, className }) => {
  return (
    <Tooltip anchorClassName={clsx("fill-current", className && className)} position="top" content={<div className="w-48">{text}</div>}>
      <IconQuestion width={size || 20} height={size || 20} />
    </Tooltip>
  );
};

export default TooltipWithIcon;
