import React from "react";
import "../tailwind.generated.css";

import { ReactComponent as WarningIcon } from "../icons/error.svg";
import { ReactComponent as InfoIcon } from "../icons/info.svg";

import clsx from "clsx";

const iconMap = {
  warning: WarningIcon,
  error: WarningIcon,
  info: InfoIcon,
}

const Banner = ({
  text,
  type,
  header,
  className,
}: {
  text: string | JSX.Element;
  type: "warning" | "error" | "info";
  header?: string;
  className?: string;
}) => {

  const IconComponent = iconMap[type];

  return (
    <div
      className={clsx(
        "p-4 w-full text-gray-900 rounded-lg",
        type === "warning" && "bg-orange-200",
        type === "error" && "bg-red-200",
        type === "info" && "bg-blue-100",
        className && className
      )}
    >
      <div className="mr-2 flex items-center">
        {IconComponent && <IconComponent
          className="fill-current text-gray-800 mr-2"
          width={24}
          height={24}
        />}
        {header && <span className="font-medium block">{header}</span>}
      </div>
      <span className="block mt-2 font-normal text-sm">{text}</span>
    </div>
  );
};

export default Banner;
