import { useState, useEffect } from "react";
import { ICardSortResponse, SortingCategory, SortingCard } from "../../../models/Response";

import { useReportConfig } from "./useReportConfig";
import { getUserDefinedCategories } from "../CardSortReport/utils";

import { generateId } from "../../../utils";
import _ from "lodash";


type useCardSortDataArgs = {
    testId: string;
    blockId: string;
    predefinedCategories: {
        id: string;
        value: string | undefined;
    }[],
    cards: {
        id: string;
        value: string;
        image: string | null | undefined;
        description: string | null | undefined;
    }[],
    responses: ICardSortResponse[],
    doNotRequireToSortAll: boolean,
    sharingToken?: string;
}

export const useCardSortData = ({
    testId,
    blockId,
    predefinedCategories,
    responses,
    doNotRequireToSortAll,
    sharingToken
}: useCardSortDataArgs) => {

    const [reportCategories, setReportCategories] = useState<Array<{ id: string, value: string | undefined, isGroup?: boolean, isCustom?: boolean }> | null>(null);
    const [cardsData, setCardsData] = useState<{ [cardId: string]: { [categoryId: string]: number } } | null>(null);
    const [categoriesData, setCategoriesData] = useState<{ [categoryId: string]: { [cardId: string]: number } } | null>(null);

    const reportConfig = useReportConfig({ testId: testId, blockId: blockId, sharingToken });
    const blockConfig = reportConfig.reportConfig?.data?.blocks[blockId];
    const groups: Array<{ id: string, name: string, categories: Array<string>, autoNameGroup?: boolean }> = blockConfig?.groups;

    function getNameBasedCategoryId(category: Partial<SortingCategory>): string | undefined {
        // If category is defined by user, we return its value. If not, we return predefined category value.
        if (category.value) {
            return category.value.trim().toLowerCase();
        } else {
            return predefinedCategories.find(c => c.id === category.id)?.value?.trim().toLowerCase();
        }
    }

    useEffect(() => {

        // Assign name based id to categories
        const responsesWithNameBasedId = responses.map(response => {
            const sorting = response.sorting.map(category => {
                const nameBasedCategoryId = getNameBasedCategoryId(category);
                if (!nameBasedCategoryId) return category;
                return {
                    ...category,
                    id: nameBasedCategoryId,
                    value: category.value
                };
            });
            return { ...response, sorting };
        });


        // Mapping categories and cards
        const tempCardsData: { [cardId: string]: { [categoryId: string]: number } } = {};
        const tempCategoriesData: { [categoryId: string]: { [cardId: string]: number } } = {};

        for (const response of responsesWithNameBasedId) {
            for (const category of response.sorting) {
                let idToUse = category.id;

                const group = groups?.find(g => g.categories.includes(category.id));
                if (group) {
                    idToUse = group.id;
                }

                for (const card of category.cards) {
                    if (!tempCardsData[card.id]) {
                        tempCardsData[card.id] = {};
                    }
                    if (!tempCardsData[card.id][idToUse]) {
                        tempCardsData[card.id][idToUse] = 0;
                    }
                    tempCardsData[card.id][idToUse]++;
                }
            }
        }

        for (const response of responsesWithNameBasedId) {
            for (const category of response.sorting) {
                let idToUse = category.id;
                // Determine if this category is part of a group
                const group = groups?.find(g => g.categories.includes(category.id));
                if (group) {
                    idToUse = group.id;
                }

                if (!tempCategoriesData[idToUse]) {
                    tempCategoriesData[idToUse] = {};
                }
                for (const card of category.cards) {
                    if (!tempCategoriesData[idToUse][card.id]) {
                        tempCategoriesData[idToUse][card.id] = 0;
                    }
                    tempCategoriesData[idToUse][card.id]++;
                }
            }
        }

        // Assign name based id to predefined categories

        const predefinedCategoriesWithNameBasedId = predefinedCategories.map(category => {
            const nameBasedCategoryId = getNameBasedCategoryId(category);
            if (!nameBasedCategoryId) return category;
            return {
                ...category,
                id: nameBasedCategoryId,
                value: category.value
            };
        });

        // Merge predefined categories and user defined categories
        const tempReportCategories = [...predefinedCategoriesWithNameBasedId, ...getUserDefinedCategories(responsesWithNameBasedId, doNotRequireToSortAll)];
        const processedReportCategories: Array<{ id: string, value: string | undefined, isGroup?: boolean, isCustom?: boolean }> = [];

        // Merge categories in groups
        for (const category of tempReportCategories) {
            // Determine if this category is part of a group
            const group = groups?.find(g => g.categories.includes(category.id));
            if (group) {
                // If the group already exists in processedReportCategories, skip this iteration
                if (processedReportCategories.find(c => c.id === group.id)) continue;

                // Merge categories in the group into one and set isGroup = true
                processedReportCategories.push({
                    id: group.id,
                    value: group.name,
                    isGroup: true,
                });
            } else {
                // If the category is not part of a group, check if it already exists in processedReportCategories. If it does, skip this iteration. If it does not, add it as is.
                if (processedReportCategories.find(c => c.id === category.id)) {
                    continue;
                }
                else {
                    processedReportCategories.push(category);
                }
            }

        }

        setReportCategories(processedReportCategories);
        setCardsData(tempCardsData);
        setCategoriesData(tempCategoriesData);
    }, [blockConfig, responses]);


    return { reportCategories, cardsData, categoriesData, reportConfig };
};
