import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

declare global {
  interface Window {
    pwconfig: { 
      languages: string[], 
      payment_methods: string[], 
      firebase_config: any,
      help_center_domain: string,
      urls: {
        license_agreement: string,
        landing_page: string
      }
    };
  }
}

// Initialize Firebase
firebase.initializeApp(window.pwconfig.firebase_config);

export let auth = firebase.auth();
export let db = firebase.firestore();
export let storage = firebase.storage();
export let FieldValue = firebase.firestore.FieldValue;
export let Timestamp = firebase.firestore.Timestamp;

export const getAuthToken = () =>
  new Promise((resolve) => {
    //@ts-ignore
    auth.currentUser.getIdToken(true).then(resolve);
  });

export const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return auth.signInWithPopup(provider);
};

export const signInWithFacebook = () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  return auth.signInWithPopup(provider);
};

export const signInWithEmail = (email: string, redirectUrl: string) => {
  let url = `${window.location.protocol}//${window.location.host}/login/with-email`;

  if (redirectUrl) {
    url = url + `?redirectUrl=${redirectUrl}`;
  }

  return auth.sendSignInLinkToEmail(email, {
    url,
    handleCodeInApp: true,
  });
};
